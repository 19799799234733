import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { AiOutlineUnorderedList, AiOutlineCalendar, AiOutlineClose, AiOutlineFacebook, AiOutlineInstagram, AiOutlineYoutube, AiOutlineFlag } from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowDropup, IoIosArrowUp } from "react-icons/io";
import { ShopListing } from './ShopListing';
import { DineListing } from './DineListing';
import { EntertainmentListing } from './EntertainmentListing';
import { ServicesListing } from './ServicesListing';
import { EventsListing } from './EventsListing';
import { DealsListing } from './DealsListing';

import ShopsIcon from '../../assets/images/icons/shop-icon-color.svg'
import DineGreyIcon from '../../assets/images/icons/dine-icon-grey.svg'
import DineIcon from '../../assets/images/icons/dine-icon-grey.svg'
import EntertainmentColorIcon from '../../assets/images/icons/puzzle-icon-color.svg'
import elevatorIcon from '../../assets/images/icons/elevator-icon.svg'
import CalendarIcon from '../../assets/images/icons/calendar-icon-color.svg'
import DealsColorIcon from '../../assets/images/icons/deals-icon-color.svg'

import jsonDataShopsEn from './data/newest_shop_list_en.json';
import jsonDataShopsAr from './data/newest_shop_list_ar.json';

import { LazyLoadImage } from "react-lazy-load-image-component";


export const ListingShoppingDesktop = ({ topNavValue }) => {
    const [t, i18n] = useTranslation("common");
    let location = useLocation();
    const params = new URLSearchParams(location.search);
    const tabParam = params.get('tab');

    const [openCard, setOpenCard] = useState(false);
    const [openShops, setOpenShops] = useState(true);
    const [openDine, setOpenDine] = useState(false);
    const [openEntertainment, setOpenEntertainment] = useState(false);
    const [openServices, setOpenServices] = useState(false);
    const [openEvents, setOpenEvents] = useState(false);
    const [openDeals, setOpenDeals] = useState(false);
    const [cardItem, setCardItem] = useState('');

    const [filteredCategories, setFilterCategories] = useState([]);


    const openCardHandler = (item) => {
        setCardItem(item)
        setOpenCard(!openCard)
    }

    const handleShopsOpen = () => {
        setOpenDine(false)
        setOpenEntertainment(false)
        setOpenServices(false)
        setOpenEvents(false)
        setOpenDeals(false)
        setOpenShops(!openShops)
    }
    const openDineHandler = () => {
        setOpenShops(false)
        setOpenEntertainment(false)
        setOpenServices(false)
        setOpenEvents(false)
        setOpenDeals(false)
        setOpenDine(!openDine)
    }

    const openEntertainmentHandler = () => {
        setOpenShops(false)
        setOpenDine(false)
        setOpenServices(false)
        setOpenEvents(false)
        setOpenDeals(false)
        setOpenEntertainment(!openEntertainment)
    }
    const openServicesHandler = () => {
        setOpenShops(false)
        setOpenDine(false)
        setOpenEntertainment(false)
        setOpenEvents(false)
        setOpenDeals(false)
        setOpenServices(!openServices)
    }
    const openEventsHandler = () => {
        setOpenShops(false)
        setOpenDine(false)
        setOpenEntertainment(false)
        setOpenServices(false)
        setOpenDeals(false)
        setOpenEvents(!openEvents)
    }
    const openDealsHandler = () => {
        setOpenShops(false)
        setOpenDine(false)
        setOpenEntertainment(false)
        setOpenServices(false)
        setOpenEvents(false)
        setOpenDeals(!openDeals)
    }

    const handleSelectedCategory = (category) => {
        setFilterCategories([...filteredCategories, category]);
    };


    const handelRemoveCategory = (category) => {
        setFilterCategories(filteredCategories.filter((item) => item !== category));
    };

    useEffect(() => {
        if (location.query === "shops" || tabParam === "shops") {
            if (location.card) {
                openCardHandler(i18n.language === "en" ? jsonDataShopsEn.shop.find((item) => item.name === location.card) : jsonDataShopsAr.shop.find((item) => item.name === location.card))
            }
            setOpenShops(true);
            setOpenDine(false)
            setOpenServices(false)
            setOpenEntertainment(false)
            setOpenEvents(false)
            setOpenDeals(false)
        }
        if (location.query === "dine" || tabParam === "dine") {
            if (location.card) {
                openCardHandler(i18n.language === "en" ? jsonDataShopsEn.dine.find((item) => item.name === location.card) : jsonDataShopsAr.dine.find((item) => item.name === location.card))
            }
            setOpenShops(false);
            setOpenDine(true)
            setOpenServices(false)
            setOpenEntertainment(false)
            setOpenEvents(false)
            setOpenDeals(false)
        }
        if (location.query === "services" || tabParam === "services") {
            setOpenShops(false);
            setOpenDine(false)
            setOpenServices(true)
            setOpenEntertainment(false)
            setOpenEvents(false)
            setOpenDeals(false)
        }
        if (location.query === "entertainment" || tabParam === "entertainment") {
            if (location.card) {
                openCardHandler(i18n.language === "en" ? jsonDataShopsEn.entertainment.find((item) => item.name === location.card) : jsonDataShopsAr.entertainment.find((item) => item.name === location.card))
            }
            setOpenShops(false);
            setOpenDine(false)
            setOpenServices(false)
            setOpenEntertainment(true)
            setOpenEvents(false)
            setOpenDeals(false)
        }
        if (location.query === "events" || tabParam === "events") {
            if (location.card) {
                openCardHandler(i18n.language === "en" ? jsonDataShopsEn.events.find((item) => item.name === location.card) : jsonDataShopsAr.events.find((item) => item.name === location.card))
            }
            setOpenShops(false);
            setOpenDine(false)
            setOpenServices(false)
            setOpenEntertainment(false)
            setOpenEvents(true)
            setOpenDeals(false)
        }
        if (location.query === "deals" || tabParam === "deals") {
            setOpenShops(false);
            setOpenDine(false)
            setOpenServices(false)
            setOpenEntertainment(false)
            setOpenEvents(false)
            setOpenDeals(true)
        }
    }, [location.query]);
    return (
        <div className={`releative flex gap-x-6 ${i18n.language === 'en' ? 'ml-14' : 'mr-14'} `}>
            <div className='w-80'>
                <div className='flex justify-center w-full gap-3 p-4 rounded-lg borde align-center border-natural-secondary-200'>
                    <AiOutlineUnorderedList className='w-6 h-6' />
                    <button className='text-xl'>
                        {t("listing.title")}
                    </button>
                </div>
                {/* <input type="text" className="mt-4 bg-transparent pb-2 text-lg placeholder-natural-secondary-400  block w-full px-0.5 border-0 border-b border-natural-secondary-100 focus:ring-0 focus:border-black focus:pb-4 focus:border-0 focus:border-b focus:border-natural-secondary-300 focus-visible:border-0 focus-visible:border-b focus-visible:border-natural-secondary-50 " placeholder={t("listing.searchText")} /> */}

                <div className='mt-4 border rounded-lg border-natural-secondary-100'>
                    <div>
                        <div onClick={handleShopsOpen} className='flex items-center justify-between p-4 cursor-pointer'>
                            <div className='flex items-center gap-x-2'>
                                <img
                                    alt="pattern"
                                    src={ShopsIcon}
                                    color='#fffff'
                                    className='w-8 h-8'
                                />
                                <p className='pt-2 font-semibold text-natural-900'>{t("listing.shoppingArea.shops")}</p>

                            </div>
                            {openShops ? (
                                <IoIosArrowUp className='w-4 h-4' />
                            ) : (
                                <IoIosArrowDown className='w-4 h-4' />
                            )}
                        </div>

                        {/* List */}
                        {openShops && (
                            <div className=''>
                                <div className='flex items-center justify-between p-4 bg-natural-secondary-50'>
                                    <div className={`px-8 cursor-pointer capitalize ${filteredCategories.includes("fashion & beauty") && "font-bold"}`} onClick={() => handleSelectedCategory("fashion & beauty")}>
                                        <p className='pt-2 text-natural-900'>{t("categories.fashion&beauty")}</p>
                                    </div>
                                    {filteredCategories.includes("fashion & beauty") &&
                                        <AiOutlineClose onClick={() => handelRemoveCategory("fashion & beauty")} className='w-6 h-6 p-1 rounded-full cursor-pointer bg-natural-secondary-100' />
                                    }
                                </div>
                                <div className='flex items-center justify-between p-4 bg-natural-secondary-50'>
                                    <div className={`px-8 cursor-pointer capitalize ${filteredCategories.includes("gifts & lifestyle") && "font-bold"}`} onClick={() => handleSelectedCategory("gifts & lifestyle")}>
                                        <p className='pt-2 text-natural-900'>{t("categories.gifts&lifestyle")}</p>
                                    </div>
                                    {filteredCategories.includes("gifts & lifestyle") &&
                                        <AiOutlineClose onClick={() => handelRemoveCategory("gifts & lifestyle")} className='w-6 h-6 p-1 rounded-full cursor-pointer bg-natural-secondary-100' />

                                    }
                                </div>
                                <div className='flex items-center justify-between p-4 bg-natural-secondary-50'>
                                    <div className={`px-8 cursor-pointer capitalize ${filteredCategories.includes("home & living") && "font-bold"}`} onClick={() => handleSelectedCategory("home & living")}>
                                        <p className='pt-2 text-natural-900'>{t("categories.home&living")}</p>
                                    </div>
                                    {filteredCategories.includes("home & living") &&
                                        <AiOutlineClose onClick={() => handelRemoveCategory("home & living")} className='w-6 h-6 p-1 rounded-full cursor-pointer bg-natural-secondary-100' />

                                    }
                                </div>
                                <div className='flex items-center justify-between p-4 bg-natural-secondary-50'>
                                    <div className={`px-8 cursor-pointer capitalize ${filteredCategories.includes("technology & electronics") && "font-bold"}`} onClick={() => handleSelectedCategory("technology & electronics")}>
                                        <p className='pt-2 text-natural-900'>{t("categories.technology&electronics")}</p>
                                    </div>
                                    {filteredCategories.includes("technology & electronics") &&
                                        <AiOutlineClose onClick={() => handelRemoveCategory("technology & electronics")} className='w-6 h-6 p-1 rounded-full cursor-pointer bg-natural-secondary-100' />

                                    }
                                </div>
                                <div className='flex items-center justify-between p-4 bg-natural-secondary-50'>
                                    <div className={`px-8 cursor-pointer capitalize ${filteredCategories.includes("health & wellness") && "font-bold"}`} onClick={() => handleSelectedCategory("health & wellness")}>
                                        <p className='pt-2 text-natural-900'>{t("categories.health&wellness")}</p>
                                    </div>
                                    {filteredCategories.includes("health & wellness") &&
                                        <AiOutlineClose onClick={() => handelRemoveCategory("health & wellness")} className='w-6 h-6 p-1 rounded-full cursor-pointer bg-natural-secondary-100' />

                                    }
                                </div>
                                <div className='flex items-center justify-between p-4 bg-natural-secondary-50'>
                                    <div className={`px-8 cursor-pointer capitalize  ${filteredCategories.includes("grocery & automotive") && "font-bold"}`} onClick={() => handleSelectedCategory("grocery & automotive")}>
                                        <p className='pt-2 text-natural-900'>{t("categories.grocery&automotive")}</p>
                                    </div>
                                    {filteredCategories.includes("grocery & automotive") &&
                                        <AiOutlineClose onClick={() => handelRemoveCategory("grocery & automotive")} className='w-6 h-6 p-1 rounded-full cursor-pointer bg-natural-secondary-100' />

                                    }
                                </div>
                            </div>
                        )}
                    </div>

                    <div>
                        <div onClick={openDineHandler} className='flex items-center justify-between p-4 cursor-pointer'>
                            <div className='flex items-center gap-x-2'>

                                <img
                                    alt="Roshn front logo"
                                    src={DineIcon}
                                    className='w-8 h-8 '
                                    color='#fffff'
                                />
                                <p className='pt-2 font-semibold text-natural-900'>{t("listing.shoppingArea.dine")}</p>

                            </div>
                            {openDine ? (
                                <IoIosArrowUp className='w-4 h-4' />
                            ) : (
                                <IoIosArrowDown className='w-4 h-4' />
                            )}
                        </div>

                        {/* List */}
                        {openDine && (
                            <div className=''>
                                <div className='flex items-center justify-between p-4 bg-natural-secondary-50'>
                                    <div className={`px-8 cursor-pointer ${filteredCategories.includes("cafes") && "font-bold"}`} onClick={() => handleSelectedCategory("cafes")}>
                                        <p className='pt-2 text-natural-900'>{t("categories.cafes")}</p>
                                    </div>
                                    {filteredCategories.includes("cafes") &&
                                        <AiOutlineClose onClick={() => handelRemoveCategory("cafes")} className='w-6 h-6 p-1 rounded-full cursor-pointer bg-natural-secondary-100' />
                                    }
                                </div>
                                <div className='flex items-center justify-between p-4 bg-natural-secondary-50'>
                                    <div className={`px-8 cursor-pointer ${filteredCategories.includes("restaurants") && "font-bold"}`} onClick={() => handleSelectedCategory("restaurants")}>
                                        <p className='pt-2 text-natural-900'>{t("categories.restaurants")}</p>
                                    </div>
                                    {filteredCategories.includes("restaurants") &&
                                        <AiOutlineClose onClick={() => handelRemoveCategory("restaurants")} className='w-6 h-6 p-1 rounded-full cursor-pointer bg-natural-secondary-100' />
                                    }
                                </div>
                                <div className='flex items-center justify-between p-4 bg-natural-secondary-50'>
                                    <div className={`px-8 cursor-pointer ${filteredCategories.includes("desserts") && "font-bold"}`} onClick={() => handleSelectedCategory("desserts")}>
                                        <p className='pt-2 text-natural-900'>{t("categories.desserts")}</p>
                                    </div>
                                    {filteredCategories.includes("desserts") &&
                                        <AiOutlineClose onClick={() => handelRemoveCategory("desserts")} className='w-6 h-6 p-1 rounded-full cursor-pointer bg-natural-secondary-100' />
                                    }
                                </div>
                                <div className='flex items-center justify-between p-4 bg-natural-secondary-50'>
                                    <div className={`px-8 cursor-pointer ${filteredCategories.includes("vegan/vegetarian") && "font-bold"}`} onClick={() => handleSelectedCategory("vegan/vegetarian")}>
                                        <p className='pt-2 text-natural-900'>{t("categories.vegan/vegetarian")}</p>
                                    </div>
                                    {filteredCategories.includes("vegan/vegetarian") &&
                                        <AiOutlineClose onClick={() => handelRemoveCategory("vegan/vegetarian")} className='w-6 h-6 p-1 rounded-full cursor-pointer bg-natural-secondary-100' />
                                    }
                                </div>
                                <div className='flex items-center justify-between p-4 bg-natural-secondary-50'>
                                    <div className={`px-8 cursor-pointer ${filteredCategories.includes("bakeries") && "font-bold"}`} onClick={() => handleSelectedCategory("bakeries")}>
                                        <p className='pt-2 text-natural-900'>{t("categories.bakeries")}</p>
                                    </div>
                                    {filteredCategories.includes("bakeries") &&
                                        <AiOutlineClose onClick={() => handelRemoveCategory("bakeries")} className='w-6 h-6 p-1 rounded-full cursor-pointer bg-natural-secondary-100' />
                                    }
                                </div>
                            </div>
                        )}
                    </div>

                    <div>
                        <div onClick={openEntertainmentHandler} className='flex items-center justify-between p-4 cursor-pointer'>
                            <div className='flex items-center gap-x-2'>
                                <img
                                    alt="Roshn front logo"
                                    src={EntertainmentColorIcon}
                                    className='w-8 h-8'
                                    color='#fffff'
                                />
                                <p className='pt-2 font-semibold text-natural-900'>{t("listing.shoppingArea.entertainment")}</p>

                            </div>
                            {openEntertainment ? (
                                <IoIosArrowUp className='w-6 h-6 p-1 rounded-full bg-natural-secondary-100' />
                            ) : (
                                <IoIosArrowDown className='w-4 h-4' />
                            )}
                        </div>

                    </div>

                    <div>
                        <div onClick={openServicesHandler} className='flex items-center justify-between p-4 cursor-pointer'>
                            <div className='flex items-center gap-x-2'>
                                <img
                                    alt="Roshn front logo"
                                    src={elevatorIcon}
                                    className='w-8 h-8'
                                    color='#fffff'
                                />
                                <p className='pt-2 font-semibold text-natural-900'>{t("listing.shoppingArea.services")}</p>

                            </div>
                            {openServices ? (
                                <IoIosArrowUp className='w-6 h-6 p-1 rounded-full bg-natural-secondary-100' />
                            ) : (
                                <IoIosArrowDown className='w-4 h-4' />
                            )}
                        </div>
                    </div>

                    <div>
                        <div onClick={openEventsHandler} className='flex items-center justify-between p-4 cursor-pointer'>
                            <div className='flex items-center gap-x-2'>
                                <img
                                    alt="Roshn front logo"
                                    src={CalendarIcon}
                                    className='w-8 h-8'
                                    color='#fffff'
                                />
                                <p className='pt-2 font-semibold text-natural-900'>{t("listing.shoppingArea.events")}</p>

                            </div>
                            {openEvents ? (
                                <IoIosArrowUp className='w-6 h-6 p-1 rounded-full bg-natural-secondary-100' />
                            ) : (
                                <IoIosArrowDown className='w-4 h-4' />
                            )}
                        </div>
                    </div>
                    <div>
                        <div onClick={openDealsHandler} className='flex items-center justify-between p-4 cursor-pointer'>
                            <div className='flex items-center gap-x-2'>
                                <img
                                    alt="Roshn front logo"
                                    src={DealsColorIcon}
                                    className='w-8 h-8'
                                    color='#fffff'
                                />
                                <p className='pt-2 font-semibold text-natural-900'>{t("listing.shoppingArea.deals")}</p>

                            </div>
                            {openDeals ? (
                                <IoIosArrowUp className='w-6 h-6 p-1 rounded-full bg-natural-secondary-100' />
                            ) : (
                                <IoIosArrowDown className='w-4 h-4' />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex-1 overflow-y-scroll' style={{ height: "700px" }}>
                <h1 className={`mt-1 text-natural-secondary-900 font-normal ${i18n.language === 'en' ? 'tracking-widestExtra' : ''}`} >{t("listing.shoppingArea.mainTitle")}</h1>
                <ShopListing isOpen={openShops} openCardHandler={openCardHandler} filteredCategories={filteredCategories} />

                <DineListing topNavValue={topNavValue} isOpen={openDine} openCardHandler={openCardHandler} filteredCategories={filteredCategories} />

                <EntertainmentListing topNavValue={topNavValue} isOpen={openEntertainment} openCardHandler={openCardHandler} />

                <ServicesListing topNavValue={topNavValue} isOpen={openServices} openCardHandler={openCardHandler} />

                <EventsListing topNavValue={topNavValue} isOpen={openEvents} openCardHandler={openCardHandler} />

                <DealsListing topNavValue={topNavValue} isOpen={openDeals} openCardHandler={openCardHandler} />


            </div>

            {/* Crad comp */}
            <div className={`${openCard ? 'block' : 'hidden'} bg-white shadow-xl z-10 mt-20  fixed top-0 right-0 left-0 rounded-xl  w-2/3  ml-auto mr-auto`}>
                <div className='relative'>
                    {/* Social media */}
                    {/* <div className='absolute flex gap-2 top-6 left-6'>
                        <a href='#' className='p-2 bg-opacity-50 rounded-md cursor-pointer bg-natural-secondary-400'>
                            <PiTwitterLogoLight className='w-6 h-6 text-white' />
                        </a>
                        <a href='#' className='p-2 bg-opacity-50 rounded-md cursor-pointer bg-natural-secondary-400'>
                            <AiOutlineFacebook className='w-6 h-6 text-white' />
                        </a>
                        <a href='#' className='p-2 bg-opacity-50 rounded-md cursor-pointer bg-natural-secondary-400'>
                            <AiOutlineInstagram className='w-6 h-6 text-white' />
                        </a>
                        <a href='#' className='p-2 bg-opacity-50 rounded-md cursor-pointer bg-natural-secondary-400'>
                            <AiOutlineYoutube className='w-6 h-6 text-white' />
                        </a>
                    </div> */}
                    <AiOutlineClose onClick={openCardHandler} className='absolute w-6 h-6 p-1 bg-white rounded-full cursor-pointer w top-6 right-6' />
                    {!openEvents &&
                        <div className='absolute w-24 h-24 left-14 top-60'>
                            <img
                                alt="pattern"
                                src={cardItem.logo}
                                color='#fffff'
                                className='object-contain w-full h-full bg-white border rounded-full border-natural-secondary-200'
                            />
                        </div>
                    }
                    <div className='absolute flex items-center gap-1 px-4 py-2 bg-primary-50 rounded-3xl right-14' style={{ top: '275px' }}>
                        <AiOutlineFlag className="w-6 h-6" />
                        <div className='pt-1'>{t('gates.title')} {cardItem.gate}</div>
                    </div>
                </div>
                <img
                    alt="pattern"
                    src={cardItem.banner}
                    className='object-cover w-full h-72 rounded-t-2xl'
                />
                <div className='flex justify-between gap-16 mx-16 mt-20 mb-12'>

                    <div className='w-full md:w-1/2'>
                        <h1 className='block text-2xl font-semibold'>{cardItem.name}</h1>
                        <span className='inline-block capitalize text-natural-secondary-600'>{cardItem.category}</span>
                        <div className='h-40 mt-6 overflow-y-scroll scrollbar-hide'>
                            {cardItem.description}
                        </div>
                    </div>

                    <div className='w-full md:w-1/2'>
                        {!openEvents &&
                            <>
                                <div className='flex w-full gap-2'>
                                    <a target='_blank' href={cardItem.location} className='flex items-center justify-center w-full px-2 py-3 text-center text-white rounded-md bg-primary-600 hover:bg-primary-800'>{t("InteractiveMap")}</a>
                                    <a target='_blank' href={cardItem.website} className='flex items-center justify-center w-full px-2 py-3 text-center bg-white border rounded-md border-natural-900 hover:bg-natural-secondary-100 text-natural-900'>{t("BrandWebsite")}</a>
                                </div>
                                <div className='mt-6 text-base'>
                                    <h1 className='font-semibold uppercase'>{t("openningHoursTitle")}</h1>
                                    <p>{t('listing.shoppingArea.openningHoursBody')}</p>
                                </div>
                            </>
                        }
                        {openEvents &&
                            <>
                                <div className='mt-6 text-base'>
                                    <h1 className='font-semibold uppercase'>{t("listing.shoppingArea.location")}</h1>
                                    <p>{cardItem.location}</p>
                                </div>
                                <div className='mt-6 text-base'>
                                    <h1 className='font-semibold uppercase'>{t("listing.shoppingArea.contactNumber")}</h1>
                                    <p>{cardItem.contactNumber}</p>
                                </div>
                                {cardItem.hasOwnProperty('hasFile') &&

                                    <div className='mt-6 text-base'>
                                        <h1 className='font-semibold uppercase'>{t("listing.eventDetails")}</h1>
                                        <a href='https://media.roshn.sa/roshn-front/links/roshn-front-summer.pdf' target='_blank' className='underline cursor-pointer'> {t("listing.download")}</a>
                                    </div>
                                }
                            </>
                        }

                    </div>
                </div>
            </div>
        </div >
    )
}


