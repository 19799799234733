import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Vox from '../../assets/images/entertainment/Vox.png';
import MagicPlanet from '../../assets/images/entertainment/magic-planet.png';
import VOV from '../../assets/images/entertainment/vov.png';
import { BiArrowFromLeft, BiArrowFromRight } from 'react-icons/bi';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';



const EntertainmentSection = ({ activeTab }) => {
    const [t, i18n] = useTranslation("common");

    return (
        <>
            {(() => {
                switch (activeTab) {
                    case "cinema": return (
                        <section className='flex justify-between gap-6 overflow-hidden'>
                            <div className="relative w-1/12 rounded-2xl blur-affect lg:w-3/12" >
                                <img
                                    alt="Roshn front logo"
                                    src={MagicPlanet}
                                    className='w-full my-4 fill-current h-96 rounded-2xl'
                                />
                                <div className={`absolute top-56 ${i18n.language === 'en' ? 'left-4' : 'right-4'} text-white drop-shadow-md lg:left-8 lg:top-44`}>
                                    <h3 className={`capitalize font-light text-xs lg:text-base ${i18n.language === 'en' ? 'tracking-widestExtra' : ''} `}>
                                        {t("entertainmentSectionHome.vox.type")}
                                    </h3>
                                    <h1 className="mt-4 mb-2 text-3xl font-light lg:text-5xl">
                                        <strong>{t("entertainmentSectionHome.vox.title")}</strong>
                                    </h1>
                                    <p className="text-3xl font-light lg:text-5xl">
                                        {t("entertainmentSectionHome.vox.subTitle")}
                                    </p>
                                    <div className="absolute flex items-center gap-2 mt-4 cursor-pointer ">
                                        <a href="" className="pt-1 text-xs group-hover:underline lg:text-base">
                                            {t("entertainmentSectionHome.vox.cta")}
                                        </a>
                                        {i18n.language === 'en' ?

                                            <IoIosArrowForward className="w-5 h-5 group-hover:ml-9 " />
                                            :
                                            <IoIosArrowBack className="w-5 h-5" />
                                        }
                                    </div>
                                </div>
                            </div>

                            <Link to={{
                                pathname: '/listing',
                                query: "entertainment",
                                card: i18n.language === "en" ? "Vox Cinema" : "فوكس سينما"
                            }} className="relative flex-1 rounded-2xl lg:w-4/5">
                                <img
                                    alt="Roshn front logo"
                                    src={Vox}
                                    className='object-cover w-full my-4 transition duration-500 transform cursor-pointer fill-current h-96 rounded-2xl hover:scale-105'
                                />
                                <div className={`absolute top-56 ${i18n.language === 'en' ? 'left-4' : 'right-4'} text-white drop-shadow-md lg:left-8 lg:top-44`}>
                                    <h3 className={`capitalize font-light text-xs lg:text-base ${i18n.language === 'en' ? 'tracking-widestExtra' : ''} `}>
                                        {t("entertainmentSectionHome.vox.type")}
                                    </h3>
                                    <h1 className="mt-4 mb-2 text-3xl font-light lg:text-5xl">
                                        <strong>{t("entertainmentSectionHome.vox.title")}</strong>
                                    </h1>
                                    <p className="text-3xl font-light lg:text-5xl">
                                        {t("entertainmentSectionHome.vox.subTitle")}
                                    </p>
                                    <div className="absolute flex items-center gap-2 mt-4 cursor-pointer ">
                                        <div className="pt-1 text-xs group-hover:underline lg:text-base">
                                            {t("entertainmentSectionHome.vox.cta")}
                                        </div>
                                        {i18n.language === 'en' ?

                                            <IoIosArrowForward className="w-5 h-5 group-hover:ml-9 " />
                                            :
                                            <IoIosArrowBack className="w-5 h-5" />
                                        }
                                    </div>
                                </div>

                            </Link>

                            <div className="relative w-1/12 rounded-2xl blur-affect lg:w-3/12">
                                <img
                                    alt="Roshn front logo"
                                    src={VOV}
                                    className='object-cover w-full my-4 fill-current h-96 rounded-2xl'
                                />

                                <div className={`absolute top-56 ${i18n.language === 'en' ? 'left-4' : 'right-4'} text-white drop-shadow-md lg:left-8 lg:top-44`}>
                                    <h3 className={`capitalize font-light text-xs lg:text-base ${i18n.language === 'en' ? 'tracking-widestExtra' : ''} `}>
                                        {t("entertainmentSectionHome.vov.type")}
                                    </h3>
                                    <h1 className="mt-4 mb-2 text-3xl font-light lg:text-5xl">
                                        <strong>{t("entertainmentSectionHome.vov.title")}</strong>
                                    </h1>
                                    <p className="text-3xl font-light lg:text-5xl">
                                        {t("entertainmentSectionHome.vov.subTitle")}
                                    </p>
                                    <div className="absolute flex items-center gap-2 mt-4 cursor-pointer ">
                                        <a href="" className="pt-1 text-xs group-hover:underline lg:text-base">
                                            {t("entertainmentSectionHome.vov.cta")}
                                        </a>
                                        {i18n.language === 'en' ?

                                            <IoIosArrowForward className="w-5 h-5 group-hover:ml-9 " />
                                            :
                                            <IoIosArrowBack className="w-5 h-5" />
                                        }
                                    </div>
                                </div>
                            </div>


                        </section>
                    )
                    case "gaming": return (
                        <section className='flex justify-between gap-6 overflow-hidden'>
                            <div className="relative w-1/12 rounded-2xl blur-affect lg:w-3/12" >
                                <img
                                    alt="Roshn front logo"
                                    src={Vox}
                                    className='w-full my-4 fill-current h-96 rounded-2xl'
                                />
                                <div className={`absolute top-56 ${i18n.language === 'en' ? 'left-4' : 'right-4'} text-white drop-shadow-md lg:left-8 lg:top-44`}>
                                    <h3 className={`capitalize font-light text-xs lg:text-base ${i18n.language === 'en' ? 'tracking-widestExtra' : ''} `}>
                                        {t("entertainmentSectionHome.vox.type")}
                                    </h3>
                                    <h1 className="mt-4 mb-2 text-3xl font-light lg:text-5xl">
                                        <strong>{t("entertainmentSectionHome.vox.title")}</strong>
                                    </h1>
                                    <p className="text-3xl font-light lg:text-5xl">
                                        {t("entertainmentSectionHome.vox.subTitle")}
                                    </p>
                                    <div className="absolute flex items-center gap-2 mt-4 cursor-pointer ">
                                        <a href="" className="pt-1 text-xs group-hover:underline lg:text-base">
                                            {t("entertainmentSectionHome.vox.cta")}
                                        </a>
                                        {i18n.language === 'en' ?

                                            <IoIosArrowForward className="w-5 h-5 group-hover:ml-9 " />
                                            :
                                            <IoIosArrowBack className="w-5 h-5" />
                                        }
                                    </div>
                                </div>
                            </div>

                            <Link to={{
                                pathname: '/listing',
                                query: "entertainment",
                                card: "VOV"
                            }} className="relative flex-1 rounded-2xl lg:w-4/5">
                                <img
                                    alt="Roshn front logo"
                                    src={VOV}
                                    className='object-cover w-full my-4 transition duration-500 transform cursor-pointer fill-current h-96 rounded-2xl hover:scale-105'
                                />

                                <div className={`absolute top-56 ${i18n.language === 'en' ? 'left-4' : 'right-4'} text-white drop-shadow-md lg:left-8 lg:top-44`}>
                                    <h3 className={`capitalize font-light text-xs lg:text-base ${i18n.language === 'en' ? 'tracking-widestExtra' : ''} `}>
                                        {t("entertainmentSectionHome.vov.type")}
                                    </h3>
                                    <h1 className="mt-4 mb-2 text-3xl font-light lg:text-5xl">
                                        <strong>{t("entertainmentSectionHome.vov.title")}</strong>
                                    </h1>
                                    <p className="text-3xl font-light lg:text-5xl">
                                        {t("entertainmentSectionHome.vov.subTitle")}
                                    </p>
                                    <div className="absolute flex items-center gap-2 mt-4 cursor-pointer ">
                                        <div className="pt-1 text-xs group-hover:underline lg:text-base">
                                            {t("entertainmentSectionHome.vov.cta")}
                                        </div>
                                        {i18n.language === 'en' ?

                                            <IoIosArrowForward className="w-5 h-5 group-hover:ml-9 " />
                                            :
                                            <IoIosArrowBack className="w-5 h-5" />
                                        }
                                    </div>
                                </div>
                            </Link>

                            <div className="relative w-1/12 rounded-2xl blur-affect lg:w-3/12">
                                <img
                                    alt="Roshn front logo"
                                    src={MagicPlanet}
                                    className='object-cover w-full my-4 fill-current h-96 rounded-2xl'
                                />

                                <div className={`absolute top-56 ${i18n.language === 'en' ? 'left-4' : 'right-4'} text-white drop-shadow-md lg:left-8 lg:top-44`}>
                                    <h3 className={`capitalize font-light text-xs lg:text-base ${i18n.language === 'en' ? 'tracking-widestExtra' : ''} `}>
                                        {t("entertainmentSectionHome.vox.type")}
                                    </h3>
                                    <h1 className="mt-4 mb-2 text-3xl font-light lg:text-5xl">
                                        <strong>{t("entertainmentSectionHome.vox.title")}</strong>
                                    </h1>
                                    <p className="text-3xl font-light lg:text-5xl">
                                        {t("entertainmentSectionHome.vox.subTitle")}
                                    </p>
                                    <div className="absolute flex items-center gap-2 mt-4 cursor-pointer ">
                                        <a href="" className="pt-1 text-xs group-hover:underline lg:text-base">
                                            {t("entertainmentSectionHome.vox.cta")}
                                        </a>
                                        {i18n.language === 'en' ?

                                            <IoIosArrowForward className="w-5 h-5 group-hover:ml-9 " />
                                            :
                                            <IoIosArrowBack className="w-5 h-5" />
                                        }
                                    </div>
                                </div>
                            </div>


                        </section>
                    )
                    default: return (
                        <section className='flex justify-between gap-6 overflow-hidden'>
                            <div className="relative w-1/12 rounded-2xl blur-affect lg:w-3/12" >
                                <img
                                    alt="Roshn front logo"
                                    src={VOV}
                                    className='w-full my-4 fill-current h-96 rounded-2xl'
                                />
                                <div className={`absolute top-56 ${i18n.language === 'en' ? 'left-4' : 'right-4'} text-white drop-shadow-md lg:left-8 lg:top-44`}>
                                    <h3 className={`capitalize font-light text-xs lg:text-base ${i18n.language === 'en' ? 'tracking-widestExtra' : ''} `}>
                                        {t("entertainmentSectionHome.vox.type")}
                                    </h3>
                                    <h1 className="mt-4 mb-2 text-3xl font-light lg:text-5xl">
                                        <strong>{t("entertainmentSectionHome.vox.title")}</strong>
                                    </h1>
                                    <p className="text-3xl font-light lg:text-5xl">
                                        {t("entertainmentSectionHome.vox.subTitle")}
                                    </p>
                                    <div className="absolute flex items-center gap-2 mt-4 cursor-pointer ">
                                        <a href="" className="pt-1 text-xs group-hover:underline lg:text-base">
                                            {t("entertainmentSectionHome.vox.cta")}
                                        </a>
                                        {i18n.language === 'en' ?

                                            <IoIosArrowForward className="w-5 h-5 group-hover:ml-9 " />
                                            :
                                            <IoIosArrowBack className="w-5 h-5" />
                                        }
                                    </div>
                                </div>
                            </div>

                            <Link to={{
                                pathname: '/listing',
                                query: "entertainment",
                                card: i18n.language === "en" ? "Magic Planet" : "ماجيك بلانيت"
                            }} className="relative flex-1 rounded-2xl lg:w-4/5">
                                <img
                                    alt="Roshn front logo"
                                    src={MagicPlanet}
                                    className='object-cover w-full my-4 transition duration-500 transform cursor-pointer fill-current h-96 rounded-2xl hover:scale-105'
                                />

                                <div className={`absolute top-56 ${i18n.language === 'en' ? 'left-4' : 'right-4'} text-white drop-shadow-md lg:left-8 lg:top-44`}>
                                    <h3 className={`capitalize font-light text-xs lg:text-base ${i18n.language === 'en' ? 'tracking-widestExtra' : ''} `}>
                                        {t("entertainmentSectionHome.magicPlanet.type")}
                                    </h3>
                                    <h1 className="mt-4 mb-2 text-3xl font-light lg:text-5xl">
                                        <strong>{t("entertainmentSectionHome.magicPlanet.title")}</strong>
                                    </h1>
                                    <p className="text-3xl font-light lg:text-5xl">
                                        {t("entertainmentSectionHome.magicPlanet.subTitle")}
                                    </p>
                                    <div className="absolute flex items-center gap-2 mt-4 cursor-pointer ">
                                        <a href="" className="pt-1 text-xs group-hover:underline lg:text-base">
                                            {t("entertainmentSectionHome.magicPlanet.cta")}
                                        </a>
                                        {i18n.language === 'en' ?

                                            <IoIosArrowForward className="w-5 h-5 group-hover:ml-9 " />
                                            :
                                            <IoIosArrowBack className="w-5 h-5" />
                                        }
                                    </div>
                                </div>
                            </Link>

                            <div className="relative w-1/12 rounded-2xl blur-affect lg:w-3/12">
                                <img
                                    alt="Roshn front logo"
                                    src={Vox}
                                    className='object-cover w-full my-4 fill-current h-96 rounded-2xl'
                                />

                                <div className={`absolute top-56 ${i18n.language === 'en' ? 'left-4' : 'right-4'} text-white drop-shadow-md lg:left-8 lg:top-44`}>
                                    <h3 className={`capitalize font-light text-xs lg:text-base ${i18n.language === 'en' ? 'tracking-widestExtra' : ''} `}>
                                        {t("entertainmentSectionHome.vox.type")}
                                    </h3>
                                    <h1 className="mt-4 mb-2 text-3xl font-light lg:text-5xl">
                                        <strong>{t("entertainmentSectionHome.vox.title")}</strong>
                                    </h1>
                                    <p className="text-3xl font-light lg:text-5xl">
                                        {t("entertainmentSectionHome.vox.subTitle")}
                                    </p>
                                    <div className="absolute flex items-center gap-2 mt-4 cursor-pointer ">
                                        <a href="" className="pt-1 text-xs group-hover:underline lg:text-base">
                                            {t("entertainmentSectionHome.vox.cta")}
                                        </a>
                                        {i18n.language === 'en' ?

                                            <IoIosArrowForward className="w-5 h-5 group-hover:ml-9 " />
                                            :
                                            <IoIosArrowBack className="w-5 h-5" />
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>
                    );
                }
            })()}
        </>
    )

}

export default EntertainmentSection;