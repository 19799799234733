import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { AiOutlineUnorderedList, AiOutlineCalendar, AiOutlineClose, AiOutlineFacebook, AiOutlineInstagram, AiOutlineYoutube, AiOutlineFlag } from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowDropup, IoIosArrowUp } from "react-icons/io";
import { LiaShoppingBagSolid } from "react-icons/lia";
import { BiDrink, BiWine, BiLogoSquarespace } from "react-icons/bi";
import { MdMiscellaneousServices } from "react-icons/md";
import Background from '../../assets/images/get-in-touch-bg.png';
import FootLockerLogo from '../../assets/images/FL.png';
import FootLockerLogoBg from '../../assets/images/foot_locker_bg.jpeg';
import FootLockerLogoBanner from '../../assets/images/fl-2.jpeg';
import RoshnfrontLogoBlack from '../../assets/images/roshnfront-logo-black.svg'
import { PiTwitterLogoLight } from 'react-icons/pi';
import CalenderColorIcon from '../../assets/images/icons/calendar-icon-color.svg'

import jsonDataShopsEn from './data/newest_shop_list_en.json';
import jsonDataShopsAr from './data/newest_shop_list_ar.json';

import jsonDataBusiEn from './data/new_bus_list_en.json';
import jsonDataBusiAr from './data/new_bus_list_ar.json';
import EventCard from '../EventCard/EventCard';
import EventCardDesktop from '../EventCardDesktop/EventCardDesktop';
import CalendarIconGrey from '../../assets/images/icons/events-empty-icon.svg'

export const EventsListing = ({ isOpen, type, openCardHandler, icon, topNavValue }) => {
    const [t, i18n] = useTranslation("common");

    // Upcoming events
    const filteredUpcomingEventsBusEn = jsonDataBusiEn.events.filter(event => event.type === "upcoming");
    const filteredUpcomingEventsShopEn = jsonDataShopsEn.events.filter(event => event.type === "upcoming");
    const filteredUpcomingEventsBusAr = jsonDataBusiAr.events.filter(event => event.type === "قادمة");
    const filteredUpcomingEventsShopAr = jsonDataShopsAr.events.filter(event => event.type === "قادمة");

    // Past events
    const filteredPastEventsBusEn = jsonDataBusiEn.events.filter(event => event.type === "history");
    const filteredPastEventsShopEn = jsonDataShopsEn.events.filter(event => event.type === "history");
    const filteredPastEventsBusAr = jsonDataBusiAr.events.filter(event => event.type === "منتهية");
    const filteredPastEventsShopAr = jsonDataShopsAr.events.filter(event => event.type === "منتهية");

    return (
        isOpen && (
            <div>
                <div className='flex items-center mt-4 gap-x-2'>
                    <img
                        alt="Roshn front logo"
                        src={CalenderColorIcon}
                        className='w-8 h-8 '
                        color='#fffff'
                    />
                    <p className='pt-2 text-2xl font-semibold text-natural-900'>{t("listing.shoppingArea.events")}</p>

                </div>
                <div className='flex flex-wrap gap-6 pt-10 pb-6' >
                    <h1 className='text-2xl font-semibold'>{t("listing.shoppingArea.upcomingEvents")}</h1>
                </div>

                {i18n.language === "en" ?
                    topNavValue != "SHOPPING_AREA" ?
                        // English > Business Area > Upcoming > Empty
                        filteredUpcomingEventsBusEn.length === 0
                            ?
                            (
                                <div className='flex flex-col items-center justify-center py-8'>
                                    <img
                                        alt="pattern"
                                        src={CalendarIconGrey}
                                        color='#fffff'
                                        className='w-32 h-32 rounded-full'
                                    />
                                    <h1 className='mt-6 text-lg font-semibold'>
                                        {t("listing.noUpcomingEvents")}
                                    </h1>
                                    <p className='my-2 text-xs'>
                                        {t("listing.eventsStayTuned")}
                                    </p>
                                </div>
                            ) :
                            // English > Business Area > Upcoming > Full
                            <div className='flex flex-wrap gap-6'>
                                {filteredUpcomingEventsBusEn.map(event => (
                                    <EventCardDesktop key={event.name} item={event} openCardHandler={openCardHandler} />
                                ))}
                            </div>
                        :
                        // English > Shopping Area > upcoming > Full
                        filteredUpcomingEventsShopEn.length === 0 ?
                            (
                                <div className='flex flex-col items-center justify-center py-8'>
                                    <img
                                        alt="pattern"
                                        src={CalendarIconGrey}
                                        color='#fffff'
                                        className='w-32 h-32 rounded-full'
                                    />
                                    <h1 className='mt-6 text-lg font-semibold'>
                                        {t("listing.noUpcomingEvents")}
                                    </h1>
                                    <p className='my-2 text-xs'>
                                        {t("listing.eventsStayTuned")}
                                    </p>
                                </div>
                            ) :
                            // English > Business Area > Upcoming > Full
                            <div className='flex flex-wrap gap-6'>
                                {filteredUpcomingEventsShopEn.map(event => (
                                    <EventCardDesktop key={event.name} item={event} openCardHandler={openCardHandler} />
                                ))}
                            </div>
                    :
                    // Arabic
                    topNavValue != "SHOPPING_AREA" ?
                        // Arabic > Business Area > Upcoming > Empty
                        filteredUpcomingEventsBusAr.length === 0
                            ?
                            (
                                <div className='flex flex-col items-center justify-center py-8'>
                                    <img
                                        alt="pattern"
                                        src={CalendarIconGrey}
                                        color='#fffff'
                                        className='w-32 h-32 rounded-full'
                                    />
                                    <h1 className='mt-6 text-lg font-semibold'>
                                        {t("listing.noUpcomingEvents")}
                                    </h1>
                                    <p className='my-2 text-xs'>
                                        {t("listing.eventsStayTuned")}
                                    </p>
                                </div>
                            ) :
                            // Arabic > Business Area > Upcoming > Full
                            <div className='flex flex-wrap gap-6'>
                                {filteredUpcomingEventsBusAr.map(event => (
                                    <EventCardDesktop key={event.name} item={event} openCardHandler={openCardHandler} />
                                ))}
                            </div>
                        :
                        // Arabic > Shopping Area > upcoming > Full
                        filteredUpcomingEventsShopAr.length === 0 ?
                            (
                                <div className='flex flex-col items-center justify-center py-8'>
                                    <img
                                        alt="pattern"
                                        src={CalendarIconGrey}
                                        color='#fffff'
                                        className='w-32 h-32 rounded-full'
                                    />
                                    <h1 className='mt-6 text-lg font-semibold'>
                                        {t("listing.noUpcomingEvents")}
                                    </h1>
                                    <p className='my-2 text-xs'>
                                        {t("listing.eventsStayTuned")}
                                    </p>
                                </div>
                            ) :
                            // English > Business Area > Upcoming > Full
                            <div className='flex flex-wrap gap-6'>
                                {filteredUpcomingEventsShopAr.map(event => (
                                    <EventCardDesktop key={event.name} item={event} openCardHandler={openCardHandler} />
                                ))}
                            </div>
                }


                <div className='flex flex-wrap gap-6 pt-10 pb-6' >
                    <h1 className='text-2xl font-semibold'>{t("listing.shoppingArea.pastEvents")}</h1>
                </div>

                <div className='flex flex-wrap gap-6 ' >
                    {
                        i18n.language === "en" ?
                            topNavValue != "SHOPPING_AREA" ?
                                filteredPastEventsBusEn.map((event) => (
                                    <EventCardDesktop key={event.name} item={event} openCardHandler={openCardHandler} />
                                ))
                                :
                                filteredPastEventsShopEn.map((event) => (
                                    <EventCardDesktop key={event.name} item={event} openCardHandler={openCardHandler} />
                                ))
                            :
                            topNavValue != "SHOPPING_AREA" ?
                                filteredPastEventsBusAr.map((event) => (
                                    <EventCardDesktop key={event.name} item={event} openCardHandler={openCardHandler} />
                                ))
                                :
                                filteredPastEventsShopAr.map((event) => (
                                    <EventCardDesktop key={event.name} item={event} openCardHandler={openCardHandler} />
                                ))
                    }

                </div>
            </div>
        )


    )
}