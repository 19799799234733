import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { AiOutlineUnorderedList, AiOutlineCalendar, AiOutlineClose, AiOutlineFacebook, AiOutlineInstagram, AiOutlineYoutube, AiOutlineFlag, AiOutlinePercentage } from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowDropup, IoIosArrowUp } from "react-icons/io";
import { LiaShoppingBagSolid } from "react-icons/lia";
import { BiDrink, BiWine, BiLogoSquarespace } from "react-icons/bi";
import { MdMiscellaneousServices } from "react-icons/md";
import Background from '../../assets/images/get-in-touch-bg.png';
import FootLockerLogo from '../../assets/images/FL.png';
import FootLockerLogoBg from '../../assets/images/foot_locker_bg.jpeg';
import FootLockerLogoBanner from '../../assets/images/fl-2.jpeg';
import RoshnfrontLogoBlack from '../../assets/images/roshnfront-logo-black.svg'
import { PiTwitterLogoLight } from 'react-icons/pi';
import DealsColorIcon from '../../assets/images/icons/deals-icon-color.svg'


export const DealsListing = ({ isOpen, type, openCardHandler, icon }) => {
    const [t, i18n] = useTranslation("common");

    return (
        isOpen && (
            <div>
                <div className='flex items-center mt-4 gap-x-2'>
                    <img
                        alt="Roshn front logo"
                        src={DealsColorIcon}
                        className='w-8 h-8 '
                        color='#fffff'
                    />
                    <p className='pt-2 text-2xl font-semibold text-natural-900'>{t("listing.shoppingArea.deals")}</p>

                </div>
                <div className='mt-40 ml-auto mr-auto w-44'>
                    <AiOutlinePercentage className='w-full h-full p-4 rounded-full text-secondary-200 bg-secondary-50' />

                </div>
                <div className='mt-4 ml-auto mr-auto text-center w-96'>
                    <h1 className='text-lg font-semibold'>
                        {t("listing.noDealsAvailable")}
                    </h1>
                    <p className='mt-2 text-sm font-normal'>
                        {t("listing.stayTunedDeals")}
                    </p>
                </div>
            </div>
        )


    )
}