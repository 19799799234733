import React, { Component } from "react";
import Slider from "react-slick";
import "./slick.css";
import "./slick-theme.css";
import Background from '../../assets/images/get-in-touch-bg.png';
import FootLockerLogoBanner from '../../assets/images/fl-2.jpeg';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";


export default class SimpleSlider extends Component {
    render() {

        const settings = {
            className: "slider",
            centerMode: true,
            infinite: true,
            centerPadding: "60px",
            slidesToShow: 3,
            slidesToScroll: 1,
            speed: 500,
            variableWidth: true,

            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        arrows: false,
                        slidesToShow: 1,
                    },
                },
            ],
        };
        return (
            <div className="slide-type-1">

                {/* <div className="absolute h-full w-full">
                    <button
                        className="arrow-btn top-1/4 prev"
                        onClick={() => this.slider.slickPrev()}
                    >
                        <IoIosArrowBack />
                    </button>
                    <button
                        className="arrow-bt top-1/4 float-right next"
                        onClick={() => this.slider.slickNext()}
                    >
                        <IoIosArrowForward />
                    </button>
                </div> */}

                <Slider {...settings}>
                    <div className="relative w-full rounded-2xl" >
                        <img
                            alt="Roshn front logo"
                            src={FootLockerLogoBanner}
                            className='fill-current h-96 w-full rounded-2xl'
                        />
                        <div className="absolute top-20 left-8 text-white drop-shadow-md">
                            <h3 className="capitalize text-base font-light" style={{ letterSpacing: '.24rem' }}>
                                VOX CINEMA
                            </h3>
                            <p className="text-5xl  font-light mt-10">
                                Here to watch <br />
                                the Big <strong>Screen 1</strong>
                            </p>

                            <a href="" className="mt-10 cursor-pointer absolute top-56 text-base">
                                Get Your Tickets
                            </a>
                        </div>
                    </div>

                    <div className="relative rounded-2xl w-full ">
                        <img
                            alt="Roshn front logo"
                            src={FootLockerLogoBanner}

                            className='fill-current  h-96 w-full rounded-2xl'
                        />

                        <div className="absolute top-20 left-8 text-white drop-shadow-md">
                            <h3 className="capitalize text-base font-light" style={{ letterSpacing: '.24rem' }}>
                                VOX CINEMA
                            </h3>
                            <p className="text-5xl  font-light mt-10">
                                Here to watch <br />
                                the Big <strong>Screen 2</strong>
                            </p>

                            <a href="" className="mt-10 cursor-pointer absolute top-56 text-base">
                                Get Your Tickets
                            </a>
                        </div>
                    </div>

                    <div className="relative rounded-2xl w-full ">
                        <img
                            alt="Roshn front logo"
                            src={FootLockerLogoBanner}

                            className='fill-current  h-96 w-full rounded-2xl'
                        />

                        <div className="absolute top-20 left-8 text-white drop-shadow-md">
                            <h3 className="capitalize text-base font-light" style={{ letterSpacing: '.24rem' }}>
                                VOX CINEMA
                            </h3>
                            <p className="text-5xl  font-light mt-10">
                                Here to watch <br />
                                the Big <strong>Screen 3</strong>
                            </p>

                            <a href="" className="mt-10 cursor-pointer absolute top-56 text-base">
                                Get Your Tickets
                            </a>
                        </div>
                    </div>

                    <div className="relative rounded-2xl w-full ">
                        <img
                            alt="Roshn front logo"
                            src={FootLockerLogoBanner}

                            className='fill-current  h-96 w-full rounded-2xl'
                        />

                        <div className="absolute top-20 left-8 text-white drop-shadow-md">
                            <h3 className="capitalize text-base font-light" style={{ letterSpacing: '.24rem' }}>
                                VOX CINEMA
                            </h3>
                            <p className="text-5xl font-light mt-10">
                                Here to watch <br />
                                the Big <strong>Screen 4</strong>
                            </p>

                            <a href="" className="mt-10 cursor-pointer absolute top-56 text-base">
                                Get Your Tickets
                            </a>
                        </div>
                    </div>

                    <div className="relative rounded-2xl w-full ">
                        <img
                            alt="Roshn front logo"
                            src={FootLockerLogoBanner}

                            className='fill-current  h-96 w-full rounded-2xl'
                        />

                        <div className="slide-content absolute top-20 left-8 text-white drop-shadow-md">
                            <h3 className="capitalize text-base font-light" style={{ letterSpacing: '.24rem' }}>
                                VOX CINEMA
                            </h3>
                            <p className="text-5xl  font-light mt-10">
                                Here to watch <br />
                                the Big <strong>Screen 5</strong>
                            </p>

                            <a href="" className="mt-10 cursor-pointer absolute top-56 text-base">
                                Get Your Tickets
                            </a>
                        </div>
                    </div>

                </Slider>
            </div>
        );
    }
}