import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { RiCarWashingLine } from 'react-icons/ri';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import AOS from 'aos';
import 'aos/dist/aos.css';
// icons
import ElectricCarIcon from '../../assets/images/icons/electric-car-icon.svg';
import RedBoxIcon from '../../assets/images/icons/redbox-icon.svg';
import PowerBankIcon from '../../assets/images/icons/power-bank-icon.svg';
import ValetParkingIcon from '../../assets/images/icons/valet-parking.svg';
import InfoDeskIcon from '../../assets/images/icons/info-desk-icon.svg';
import ATMIcon from '../../assets/images/icons/atm-machine-icon.svg';



const Services = () => {
    const [t, i18n] = useTranslation("common");

    AOS.init();

    return (
        <section data-aos="fade-up" data-aos-delay="100"
            data-aos-duration="500" className='px-4 md:px-14 mt-14'>
            <div className='flex items-baseline justify-between space-x-2 flex-nowrap md:mb-'>
                <h3 className='text-2xl md:text-5xl tracking-[.24em] text-natural-secondary-900 font-semibold'>{t("services")}</h3>
                <Link to={{
                    pathname: '/listing',
                    query: "services"
                }} className='text-xl tracking-[.24em] hidden text-natural-secondary-900 font-semibold lg:block'>{t("viewMore")} >  </Link>
            </div>
            <div className='flex justify-between gap-4 px-2 pt-6 pb-12 overflow-x-auto scrollbar-hide '>
                <div data-aos="fade-in" data-aos-offset="200"
                    data-aos-delay="40"
                    data-aos-duration="300"
                    data-aos-easing="ease-in-out" className='px-6 py-6 bg-white shadow-lg  border-natural-50 rounded-2xl hover:shadow-xl lg:w-64' style={{ minWidth: "200px" }}>
                    <div className='flex items-center gap-2'>
                        <img
                            alt="icon"
                            src={ElectricCarIcon}
                            // color='#2A1815'
                            width={100}
                            height={65}
                            className='w-8 h-8'
                        />
                        <span className='w-full border-t border-primary-700'></span>
                    </div>

                    <div className='mt-14 text-natural-900'>
                        <h1 className='text-xl font-semibold'>
                            {t("carCharger")}
                        </h1>
                    </div>
                </div>

                <div data-aos="fade-in" data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="400"
                    data-aos-easing="ease-in-out" className='w-5/6 px-6 py-6 bg-white shadow-lg border-natural-50 rounded-2xl hover:shadow-xl lg:w-64' style={{ minWidth: "200px" }}>
                    <div className='flex items-center gap-2'>
                        <img
                            alt="icon"
                            src={RedBoxIcon}
                            // color='#2A1815'
                            width={100}
                            height={65}
                            className='w-8 h-8'
                        />
                        <span className='w-full border-t border-secondary-700'></span>
                    </div>

                    <div className='mt-14 text-natural-900'>
                        <h1 className='text-xl font-semibold'>
                            {t("redBox")}
                        </h1>
                    </div>
                </div>

                <div data-aos="fade-in" data-aos-offset="200"
                    data-aos-delay="80"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" className='px-6 py-6 bg-white shadow-lg  border-natural-50 rounded-2xl hover:shadow-xl lg:w-64' style={{ minWidth: "200px" }}>
                    <div className='flex items-center gap-2'>
                        <img
                            alt="icon"
                            src={ATMIcon}
                            // color='#2A1815'
                            width={100}
                            height={65}
                            className='w-8 h-8'
                        />
                        <span className='w-full border-t border-supporting-700'></span>
                    </div>

                    <div className='mt-14 text-natural-900'>
                        <h1 className='text-xl font-semibold'>
                            {t("atmMachines")}
                        </h1>
                    </div>
                </div>
                <div data-aos="fade-in" data-aos-offset="200"
                    data-aos-delay="80"
                    data-aos-duration="50"
                    data-aos-easing="ease-in-out" className='px-6 py-6 bg-white shadow-lg  border-natural-50 rounded-2xl hover:shadow-xl lg:w-64' style={{ minWidth: "200px" }}>
                    <div className='flex items-center gap-2'>
                        <img
                            alt="icon"
                            src={InfoDeskIcon}
                            // color='#2A1815'
                            width={100}
                            height={65}
                            className='w-8 h-8'
                        />
                        <span className='w-full border-t border-natural-secondary-700'></span>
                    </div>

                    <div className='mt-14 text-natural-900'>
                        <h1 className='text-xl font-semibold'>
                            {t("infoDesk")}
                        </h1>
                    </div>
                </div>

                <div data-aos="fade-in" data-aos-offset="200"
                    data-aos-delay="100"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out" className='px-6 py-6 bg-white shadow-lg  border-natural-50 rounded-2xl hover:shadow-xl lg:w-64' style={{ minWidth: "200px" }}>
                    <div className='flex items-center gap-2'>
                        <img
                            alt="icon"
                            src={ValetParkingIcon}
                            // color='#2A1815'
                            width={100}
                            height={65}
                            className='w-8 h-8'
                        />
                        <span className='w-full border-t border-natural-400'></span>
                    </div>

                    <div className='mt-14 text-natural-900'>
                        <h1 className='text-xl font-semibold'>
                            {t("valetParking")}
                        </h1>
                    </div>
                </div>


            </div>

            <Link to={{
                pathname: '/listing',
                query: "services"
            }} className="block w-full px-2 my-6 lg:hidden">
                <button className="w-full p-4 border rounded-lg border-natural-900">
                    {t('viewMore')}
                </button>
            </Link>
        </section>
    )
}

export default Services;