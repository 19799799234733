import React, { Component, useState } from "react";
import { useTranslation } from "react-i18next";


import Slider from "react-slick";
import "./slick.css";
import "./slick-theme.css";
import Background from '../../assets/images/get-in-touch-bg.png';
import FootLockerLogoBanner from '../../assets/images/fl-2.jpeg';
import UarthBanner from '../../assets/images/dine/-urth.png';
import KimonoBanner from '../../assets/images/dine/card-banner-kimono.png';
import PiattoBanner from '../../assets/images/dine/card-banner-piatto.png';
import ShrimpZoneBanner from '../../assets/images/dine/card-banner-shrimpzone.png';
import StarbuckBanner from '../../assets/images/dine/starbuck1.png';
import MBBanner from '../../assets/images/dine/MB.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';


import { AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import FootLockerLogo from '../../assets/images/FL.png';

// Logos

import UarthLogo from '../../assets/images/logos/urth-logo.png';
import KimonoLogo from '../../assets/images/logos/kimono-logo.png';
import PiattoLogo from '../../assets/images/logos/piatto-logo.png';
import ShrimpZoneLogo from '../../assets/images/logos/Shrimpzone-logo.png';
import StarbuckLogo from '../../assets/images/logos/starbox-log.png';
import MangoliaLogo from '../../assets/images/logos/magnolia-log.png';

import jsonDataShopsEn from '../ListingDesktop/data/newest_shop_list_en.json';
import jsonDataShopsAr from '../ListingDesktop/data/newest_shop_list_ar.json';


const Slider2Dine = () => {
    const [t, i18n] = useTranslation("common");

    const [oldSlide, setOldSlide] = useState(1);
    const [activeSlide, setActiveSlide] = useState(0);
    const [activeSlide2, setActiveSlide2] = useState(0);


    const PrevArrow = (props) => {
        const { className, style, onClick } = props;

        return (
            i18n.language === "en" ?
                <div
                    onClick={onClick}

                    className="slick-prev"
                    style={{ position: 'absolute', top: '111%', left: 30 }}
                >
                    <AiOutlineArrowLeft className="w-6 h-6 text-natural-900" />
                </div>
                :
                <div
                    onClick={onClick}
                    className="slick-prev"
                    style={{ position: 'absolute', top: '111%', left: "92%" }}
                >
                    <AiOutlineArrowRight className="w-6 h-6 text-natural-900" />

                </div>
        );
    };

    const NextArrow = (props) => {
        const { className, style, onClick } = props;

        return (
            i18n.language === "en" ?
                <div
                    onClick={onClick}
                    className="bg-transparent slick-next"
                    style={{ position: 'absolute', left: 70, top: '111%' }}
                >
                    <AiOutlineArrowRight className="w-6 h-6 text-natural-900" />
                </div>
                :
                <div
                    onClick={onClick}
                    className="slick-next "
                    style={{ position: 'absolute', left: "89%", top: '111%' }}
                >
                    <AiOutlineArrowLeft className="w-6 h-6 text-natural-900" />
                </div>
        );
    };

    const settings = {
        dots: false,
        className: "slider variableWidth",
        // centerMode: i18n.language === "en" ? true : false,
        infinite: true,
        // centerPadding: i18n.language === "en" ? "60" : "380",
        slidesToShow: 3,
        slidesToScroll: i18n.language === "en" ? 1 : -1,
        speed: 500,
        variableWidth: true,
        rtl: true,

        arrow: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: false,
                    slidesToShow: 3,
                    centerMode: false,

                },
            },
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    slidesToShow: 3,
                    centerMode: false,
                    slidesToScroll: 1,


                },
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    centerMode: false,
                    slidesToScroll: 1,

                },
            },
        ],


        beforeChange: (current, next) => {
            setOldSlide(current);
            setActiveSlide(next);
        },
        afterChange: current => setActiveSlide2(current)

    };
    return (
        <div className="slide-type-2 ">
            <Slider
                {...settings}>
                {
                    i18n.language === "en" ?
                        jsonDataShopsEn.dine.filter((item) => item.highlighted === true).map((item) => (
                            <Link key={item.name} to={{
                                pathname: '/listing',
                                query: "dine",
                                card: item.name
                            }} className='relative top-0 bg-white border cursor-pointer group rounded-2xl border-natural-secondary-200' >
                                {/* <div className="w-full h-32 bg-primary-400 rounded-t-2xl"></div> */}
                                <LazyLoadImage
                                    alt="pattern"
                                    src={item.banner}
                                    color='#fffff'
                                    effect="blur"
                                    className='w-64 bg-cover h-44 rounded-t-2xl'
                                />
                                <div className='absolute left-0 right-0 p-2 ml-auto mr-auto bg-white rounded-full h-28 w-28 top-32 '>
                                    <img
                                        alt="pattern"
                                        src={item.logo}
                                        color='#fffff'
                                        className='object-contain w-full h-full p-2 border rounded-full border-natural-50 group-hover:border-natural-900'
                                    />

                                </div>
                                <div className={`mt-16 mx-4 text-center ${i18n.language === "en" ? "md:text-left" : "md:text-right"}`}>
                                    <h1 className='block text-lg font-semibold'>{item.name}</h1>
                                    <span className='inline-block capitalize text-natural-secondary-600'>{item.category}</span>
                                </div>

                                <div className='flex justify-between py-2 mx-4 mt-8'>
                                    <div>Gate {item.gate}</div>
                                    <div className="group-hover:underline">{t('viewMore')}</div>
                                </div>

                            </Link>
                        )) :
                        jsonDataShopsAr.dine.filter((item) => item.highlighted === true).map((item) => (
                            <Link key={item.name} to={{
                                pathname: '/listing',
                                query: "dine",
                                card: item.name
                            }} className='relative top-0 bg-white border cursor-pointer group rounded-2xl border-natural-secondary-200' >
                                {/* <div className="w-full h-32 bg-primary-400 rounded-t-2xl"></div> */}
                                <LazyLoadImage
                                    alt="pattern"
                                    src={item.banner}
                                    color='#fffff'
                                    effect="blur"
                                    className='w-64 bg-cover h-44 rounded-t-2xl'
                                />
                                <div className='absolute left-0 right-0 p-2 ml-auto mr-auto bg-white rounded-full h-28 w-28 top-32 '>
                                    <img
                                        alt="pattern"
                                        src={item.logo}
                                        color='#fffff'
                                        className='object-contain w-full h-full p-2 border rounded-full border-natural-50 group-hover:border-natural-900'
                                    />

                                </div>
                                <div className={`mt-16 mx-4 text-center ${i18n.language === "en" ? "md:text-left" : "md:text-right"}`}>
                                    <h1 className='block text-lg font-semibold'>{item.name}</h1>
                                    <span className='inline-block capitalize text-natural-secondary-600'>{item.category}</span>
                                </div>

                                <div className='flex justify-between py-2 mx-4 mt-8'>
                                    <div>بوابة {item.gate}</div>
                                    <div className="group-hover:underline">{t('viewMore')}</div>
                                </div>

                            </Link>
                        ))

                }
            </Slider>
            <div className={` hidden lg:flex mx-6 py-8  items-baseline justify-between text-natural-900`}>
                <div className="ml-20">
                    <strong className="text-2xl">0{activeSlide + 1} </strong> /09
                </div>

                <Link to={{
                    pathname: '/listing',
                    query: "dine"
                }} className='text-base tracking-[.24em] text-natural-secondary-900 font-semibold'>{t("viewMore")} >  </Link>
            </div>
            <Link to={{
                pathname: '/listing',
                query: "dine"
            }} className="block w-full px-2 lg:hidden">
                <button className="w-full p-4 border rounded-lg border-natural-900">
                    {t("viewMore")}
                </button>
            </Link>



        </div>
    );
}

export default Slider2Dine;