import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import RoshnfrontLogoBlack from '../../assets/images/roshnfront-logo-black.svg'
import { NavLink, useHistory } from "react-router-dom";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import { CiMenuBurger } from "react-icons/ci";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { BsChevronRight } from "react-icons/bs";
import Background from '../../assets/images/get-in-touch-bg.png';
import NavBarBg from '../../assets/images/navbar-bg-outline.svg';
import ShopsIcon from '../../assets/images/icons/shop-icon-color.svg'
import DineIcon from '../../assets/images/icons/dine-icon-grey.svg'
import EntertainmentColorIcon from '../../assets/images/icons/puzzle-icon-color.svg'
import elevatorIcon from '../../assets/images/icons/elevator-icon.svg'
import CalendarIcon from '../../assets/images/icons/calendar-icon-color.svg'
import DealsColorIcon from '../../assets/images/icons/deals-icon-color.svg'

import communityIconColor from '../../assets/images/icons/community-icon-1.svg'
import VistiorsIcon from '../../assets/images/icons/visitor-icon.svg'

import { AiOutlineUnorderedList, AiOutlineCalendar, AiOutlineClose } from "react-icons/ai";
import { LiaShoppingBagSolid } from "react-icons/lia";
import { BiDrink, BiWine, BiLogoSquarespace } from "react-icons/bi";
import { MdMiscellaneousServices } from "react-icons/md";


export const Header = ({ changeLanguageHandler, language, page, changeTopNavHandler, location, topNavValue }) => {
  const [t, i18n] = useTranslation("common");
  const [openSideNav, setOpenSideNav] = useState(false);

  const closeNavHandler = () => {
    setOpenSideNav(false)
  }
  return (
    <main className="bg-natural-secondary-50">
      {(location === "/get-in-touch" || location === "/listing") && (
        <div className="flex text-sm font-medium md:text-lg">
          <div onClick={() => changeTopNavHandler("SHOPPING_AREA")} className={`text-natural-900 cursor-pointer ${topNavValue === "SHOPPING_AREA" ? "bg-white border-b-2 border-primary-600 " : ""} hover:bg-primary-50 border-spacing-1 py-6 px-8 md:px-14`}>{t("ShoppingArea")}</div>
          <div onClick={() => changeTopNavHandler("BUSINESS_AREA")} className={`text-natural-900  cursor-pointer ${topNavValue === "BUSINESS_AREA" ? "bg-white border-b-2 border-primary-600 " : ""} py-6 px-8 hover:bg-primary-50 md:px-14`}>{t("BusinessArea")}</div>
        </div>
      )}

      {
        language != "arabic" ? (
          <div className="flex items-center justify-between w-full p-4 bg-white shadow-sm hero-with-nav-sec md:px-14 md:py-8 shadow-primary-50 text-natural-900">
            <CiMenuBurger className='w-6 h-6 cursor-pointer' onClick={() => setOpenSideNav(!openSideNav)} />
            <Link to="/">
              <img
                alt="logo"
                src={RoshnfrontLogoBlack}
                // color='#2A1815'
                width={100}
                height={65}
              />
            </Link>
            <a className="capitalize cursor-pointer" onClick={() => changeLanguageHandler("ar")}>العربية</a>
          </div>
        ) : (
          <div className="flex items-center justify-between w-full px-8 py-6 bg-white shadow-sm hero-with-nav-sec md:px-14 shadow-primary-50 text-natural-900">
            <CiMenuBurger className='w-6 h-6 cursor-pointer' onClick={() => setOpenSideNav(!openSideNav)} />
            <Link to="/">
              <img
                alt="logo"
                src={RoshnfrontLogoBlack}
                // color='#2A1815'
                width={100}
                height={65}
              />
            </Link>
            <a className="capitalize cursor-pointer" onClick={() => changeLanguageHandler("en")}>English</a>
          </div>
        )
      }


      <div className={`${openSideNav ? "block" : "hidden"} flex flex-col justify-between fixed left-0 top-0 ${i18n.language === "en" ? 'bg-right' : 'bg-left'} px-8 md:px-14 py-14 overflow-hidden w-full h-full z-50 bg-white`} style={{ backgroundImage: `url(${NavBarBg})`, backgroundRepeat: 'no-repeat' }}>
        <div className='flex justify-between pb-6 border-b text-natural-900 border-natural-secondary-300'>
          <h3 className={`text-base ${i18n.language === 'en' ? 'tracking-widestExtra' : ''}`}>
            {topNavValue === "SHOPPING_AREA" ?
              t("ShoppingArea")
              :
              t("BusinessArea")
            }
          </h3>
          <AiOutlineClose className='w-6 h-6 cursor-pointer' onClick={() => setOpenSideNav(false)} />
        </div>
        {topNavValue === "SHOPPING_AREA" ?
          <div className='mt-6 text-natural-900'>
            <div className="">
              <div className='flex items-center gap-x-4'>
                <img
                  alt="pattern"
                  src={ShopsIcon}
                  color='#fffff'
                  className='w-8 h-8'
                />
                <Link onClick={closeNavHandler} to="/listing" className='pt-2 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.shoppingArea.shops")}</Link>

              </div>

              <div className='flex items-center mt-6 gap-x-4 md:mt-8'>
                <img
                  alt="pattern"
                  src={DineIcon}
                  color='#fffff'
                  className='w-8 h-8'
                />
                <Link onClick={closeNavHandler} to="/listing" className='pt-2 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.shoppingArea.dine")}</Link>

              </div>


              <div className='flex items-center mt-6 gap-x-4 md:mt-8'>
                <img
                  alt="pattern"
                  src={EntertainmentColorIcon}
                  color='#fffff'
                  className='w-8 h-8'
                />
                <Link onClick={closeNavHandler} to="/listing" className='pt-2 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.shoppingArea.entertainment")}</Link>

              </div>
              <div className='flex items-center mt-6 gap-x-4 md:mt-8'>
                <img
                  alt="pattern"
                  src={elevatorIcon}
                  color='#fffff'
                  className='w-8 h-8'
                />
                <Link onClick={closeNavHandler} to="/listing" className='pt-2 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.shoppingArea.services")}</Link>

              </div>
              <div className='flex items-center mt-6 gap-x-4 md:mt-8'>
                <img
                  alt="pattern"
                  src={CalendarIcon}
                  color='#fffff'
                  className='w-8 h-8'
                />
                <Link onClick={closeNavHandler} to="/listing" className='pt-2 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.shoppingArea.events")}</Link>

              </div>
              <div className='flex items-center mt-6 gap-x-4 md:mt-8'>
                <img
                  alt="pattern"
                  src={DealsColorIcon}
                  color='#fffff'
                  className='w-8 h-8'
                />
                <Link onClick={closeNavHandler} to="/listing" className='pt-2 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.shoppingArea.deals")}</Link>

              </div>
            </div>

          </div>
          :
          <div className='mt-10 text-natural-900'>
            <div className="">
              <div className='flex items-center gap-x-4 '>
                <img
                  alt="pattern"
                  src={communityIconColor}
                  color='#fffff'
                  className='w-8 h-8 mt-3'
                />
                {/* <LiaShoppingBagSolid className="w-8 h-8 p-1 mt-4 rounded-md bg-primary-100 text-natural-secondary-700" /> */}
                <Link to={{
                  pathname: '/listing',
                  query: "business-community"
                }} className='mt-5 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.businessArea.community")}</Link>
              </div>
              <div className='flex items-center mt-4 gap-x-4 md:mt-8'>
                <img
                  alt="Roshn front logo"
                  src={DineIcon}
                  className='w-8 h-8 mt-3'
                  color='#fffff'
                />
                <Link to={{
                  pathname: '/listing',
                  query: "dine"
                }} className='mt-5 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.businessArea.dine")}</Link>
              </div>
              <div className='flex items-center mt-4 gap-x-4 md:mt-8'>
                <img
                  alt="Roshn front logo"
                  src={elevatorIcon}
                  className='w-8 h-8 mt-3'
                  color='#fffff'
                />
                <Link to={{
                  pathname: '/listing',
                  query: "business-services"
                }} className='mt-5 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.businessArea.services")}</Link>
              </div>
              <div className='flex items-center mt-4 gap-x-4 md:mt-8'>
                <img
                  alt="Roshn front logo"
                  src={CalendarIcon}
                  className='w-8 h-8 mt-3'
                  color='#fffff'
                />
                <Link to={{
                  pathname: '/listing',
                  query: "business-events"
                }} className='mt-5 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.businessArea.events")}</Link>
              </div>
              <div className='flex items-center mt-4 gap-x-4 md:mt-8'>
                <img
                  alt="Roshn front logo"
                  src={VistiorsIcon}
                  className='w-8 h-8 mt-3'
                  color='#fffff'
                />
                <a target="_blank" href="https://frontvisit.com/" className='mt-5 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.businessArea.visitors")}</a>
              </div>
            </div>

          </div>
        }

        <div className='flex flex-col items-center justify-center py-4 mt-6 space-y-2 font-medium border-t md:flex-row md:space-y-0 md:justify-between text-natural-900 border-natural-secondary-300 md:py-10'>
          <Link onClick={closeNavHandler} to="/about" className='cursor-pointer'>{t("aboutRoshnFront")}</Link>
          <a target="_blank" href="https://wayfinding.roshn.sa/amap/map.php?site=ROSHNFRONT" className='cursor-pointer'>{t("InteractiveMap")}</a>
          <Link onClick={closeNavHandler} to="/get-in-touch" className='cursor-pointer'>{t("contactUs")}</Link>
        </div>
      </div>
    </main >
  );
};
