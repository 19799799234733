import React, { Component, useState } from "react";
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';

import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom/cjs/react-router-dom.min";


const ThriveSection = () => {
    const [t, i18n] = useTranslation("common");
    AOS.init();

    const [oldSlide, setOldSlide] = useState(1);
    const [activeSlide, setActiveSlide] = useState(1);
    const [activeSlide2, setActiveSlide2] = useState(1);

    return (
        <section data-aos="fade-up" data-aos-delay="100"
            data-aos-duration="500" className='mt-14 bg-white'>
            <div className='flex flex-wrap justify-between p-4 lg:p-14 md:flex-nowrap'>
                <p className='text-2xl md:text-5xl lg:w-1/3'>
                    <span className='text-secondary-900 leading-5 sm:leading-10' >{t("businessAreaPage.perfectPlace.title1")}  </span> <br /> {t("businessAreaPage.perfectPlace.title2")} <br /> {t("businessAreaPage.perfectPlace.title3")}

                    <Link className="flex flex-nowrap items-center gap-2 text-sm mt-6 group" to="/get-in-touch">
                        <span className="group-hover:underline"><strong>{t("businessAreaPage.perfectPlace.cta")}
                        </strong> </span>
                        {i18n.language === 'en' ?

                            <IoIosArrowForward className="h-5 w-5 group-hover:ml-9 " />
                            :
                            <IoIosArrowBack className="h-5 w-5" />
                        }
                    </Link>
                </p>
                <p className='text-natural-900 mt-8 text-sm lg:w-2/4 lg:text-lg lg:mt-0'>
                    {t("businessAreaPage.perfectPlace.body1")} <br />
                    {t("businessAreaPage.perfectPlace.body2")} <br />
                    {t("businessAreaPage.perfectPlace.body3")} <br />
                </p>

            </div>
        </section>
    );
}

export default ThriveSection;