import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import Skeleton from 'react-skeleton-loader';

import jsonDataShopsEn from './data/newest_shop_list_en.json';
import jsonDataShopsAr from './data/newest_shop_list_ar.json';

import jsonDataBusiEn from './data/new_bus_list_en.json';
import jsonDataBusiAr from './data/new_bus_list_ar.json';

import DineIcon from '../../assets/images/icons/dine-icon.svg'

import { LazyLoadImage } from "react-lazy-load-image-component";


const CATEGORIES_LOOKUP = {
    "cafes": "مقاهي",
    "restaurants": "مطاعم",
    "desserts": "حلويات",
    "vegan/vegetarian": "طعام نباتي",
    "bakeries": "مخابز"
}

export const DineListing = ({ isOpen, topNavValue, openCardHandler, icon, filteredCategories }) => {
    const [t, i18n] = useTranslation("common");
    const [filteredList, setFilteredList] = useState([]);


    function filterList(list1, list2) {
        if (list2.length === 0) {
            setFilteredList(list1);
        } else {
            const filteredList = list1.filter(item => list2.includes(item.category));
            setFilteredList(filteredList);
        }
    }

    useEffect(() => {
        if (i18n.language === "en") {
            filterList(topNavValue === "SHOPPING_AREA" ? jsonDataShopsEn.dine : jsonDataBusiEn.dine, filteredCategories);
        } else {
            const translatedFilters = filteredCategories.map(category => CATEGORIES_LOOKUP[category]);
            filterList(topNavValue === "SHOPPING_AREA" ? jsonDataShopsAr.dine : jsonDataBusiAr.dine, translatedFilters);
        }
    }, [filteredCategories, filteredList, i18n.language])
    return (
        isOpen && (
            <div>
                <div className='flex items-center mt-4 gap-x-2'>
                    <img
                        alt="Roshn front logo"
                        src={DineIcon}
                        className='w-8 h-8 '
                        color='#fffff'
                    />
                    <p className='pt-2 text-2xl font-semibold text-natural-900'>{t("listing.shoppingArea.dine")}</p>

                </div>
                <div className='flex flex-wrap gap-6 py-10' >
                    {
                        i18n.language === "en" ?
                            topNavValue != "SHOPPING_AREA" ?
                                filteredList.map((item) => (
                                    <div key={item.name} onClick={() => openCardHandler(item)} className='relative border cursor-pointer rounded-2xl h-96 border-natural-secondary-200' style={{ width: '280px' }} >
                                        {/* <div className="w-full h-32 bg-primary-400 rounded-t-2xl"></div> */}

                                        <LazyLoadImage
                                            alt="pattern"
                                            src={item.banner}
                                            color='#fffff'
                                            className='w-full h-1/2 rounded-t-2xl objec-cover img-lazy'
                                            width={"100%"} height={"50%"}
                                        />
                                        <div className='absolute left-0 right-0 w-24 h-24 p-2 ml-auto mr-auto bg-white border rounded-full top-32'>
                                            <LazyLoadImage
                                                alt="pattern"
                                                src={item.logo}
                                                color='#fffff'
                                                className='object-contain w-full h-full rounded-full img-lazy'
                                                width={"100%"} height={"100%"}
                                            />

                                        </div>
                                        <div className='mx-4 mt-12'>
                                            <h1 className='block text-lg font-semibold truncate'>{item.name}</h1>
                                            <span className='inline-block capitalize text-natural-secondary-600'>{item.category}</span>
                                        </div>

                                        <div className='flex justify-between mx-4 mt-14'>
                                            <div>{t('gates.title')} {item.gate}</div>
                                            <div>{t("viewMore")}</div>
                                        </div>

                                    </div>
                                ))
                                :
                                filteredList.map((item) => (
                                    <div key={item.name} onClick={() => openCardHandler(item)} className='relative border cursor-pointer rounded-2xl h-96 border-natural-secondary-200' style={{ width: '280px' }} >
                                        {/* <div className="w-full h-32 bg-primary-400 rounded-t-2xl"></div> */}

                                        <LazyLoadImage
                                            alt="pattern"
                                            src={item.banner}
                                            color='#fffff'
                                            className='w-full h-1/2 rounded-t-2xl objec-cover img-lazy'
                                            width={"100%"} height={"50%"}
                                        />
                                        <div className='absolute left-0 right-0 w-24 h-24 p-2 ml-auto mr-auto bg-white border rounded-full top-32'>
                                            <LazyLoadImage
                                                alt="pattern"
                                                src={item.logo}
                                                color='#fffff'
                                                className='object-contain w-full h-full rounded-full img-lazy'
                                                width={"100%"} height={"100%"}
                                            />

                                        </div>
                                        <div className='mx-4 mt-12'>
                                            <h1 className='block text-lg font-semibold truncate'>{item.name}</h1>
                                            <span className='inline-block capitalize text-natural-secondary-600'>{item.category}</span>
                                        </div>

                                        <div className='flex justify-between mx-4 mt-14'>
                                            <div>{t('gates.title')} {item.gate}</div>
                                            <div>{t("viewMore")}</div>
                                        </div>

                                    </div>
                                ))
                            :
                            topNavValue != "SHOPPING_AREA" ?
                                filteredList.map((item) => (
                                    <div key={item.name} onClick={() => openCardHandler(item)} className='relative border cursor-pointer rounded-2xl h-96 border-natural-secondary-200' style={{ width: '280px' }} >
                                        {/* <div className="w-full h-32 bg-primary-400 rounded-t-2xl"></div> */}

                                        <LazyLoadImage
                                            alt="pattern"
                                            src={item.banner}
                                            color='#fffff'
                                            className='w-full h-1/2 rounded-t-2xl objec-cover img-lazy'
                                            width={"100%"} height={"50%"}
                                        />
                                        <div className='absolute left-0 right-0 w-24 h-24 p-2 ml-auto mr-auto bg-white border rounded-full top-32'>
                                            <LazyLoadImage
                                                alt="pattern"
                                                src={item.logo}
                                                color='#fffff'
                                                className='object-contain w-full h-full rounded-full img-lazy'
                                                width={"100%"} height={"100%"}
                                            />

                                        </div>
                                        <div className='mx-4 mt-12'>
                                            <h1 className='block text-lg font-semibold truncate'>{item.name}</h1>
                                            <span className='inline-block text-natural-secondary-600'>{item.category}</span>
                                        </div>

                                        <div className='flex justify-between mx-4 mt-14'>
                                            <div>{t('gates.title')} {item.gate}</div>
                                            <div>{t("viewMore")}</div>
                                        </div>

                                    </div>
                                ))
                                :
                                filteredList.map((item) => (
                                    <div key={item.name} onClick={() => openCardHandler(item)} className='relative border cursor-pointer rounded-2xl h-96 border-natural-secondary-200' style={{ width: '280px' }} >
                                        {/* <div className="w-full h-32 bg-primary-400 rounded-t-2xl"></div> */}

                                        <LazyLoadImage
                                            alt="pattern"
                                            src={item.banner}
                                            color='#fffff'
                                            className='w-full h-1/2 rounded-t-2xl objec-cover img-lazy'
                                            width={"100%"} height={"50%"}
                                        />
                                        <div className='absolute left-0 right-0 w-24 h-24 p-2 ml-auto mr-auto bg-white border rounded-full top-32'>
                                            <LazyLoadImage
                                                alt="pattern"
                                                src={item.logo}
                                                color='#fffff'
                                                className='object-contain w-full h-full rounded-full img-lazy'
                                                width={"100%"} height={"100%"}
                                            />

                                        </div>
                                        <div className='mx-4 mt-12'>
                                            <h1 className='block text-lg font-semibold truncate'>{item.name}</h1>
                                            <span className='inline-block capitalize text-natural-secondary-600'>{item.category}</span>
                                        </div>

                                        <div className='flex justify-between mx-4 mt-14'>
                                            <div>{t('gates.title')} {item.gate}</div>
                                            <div>{t("viewMore")}</div>
                                        </div>

                                    </div>
                                ))
                    }

                </div>
            </div>
        )


    )
}