import React, { Suspense, useContext, useState, useEffect, useReducer } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import ReactGA from 'react-ga4';
import { routes } from "./routes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AppContext, AppInitState, AppReducer } from "../src/contexts/AppContext";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { clarity } from 'react-microsoft-clarity';
import { ShoppingArea } from "./views/ShoppingArea";
import { BusinessArea } from "./views/BusinessArea";
import { Contact } from "./views/Contact";
import { About } from "./views/About";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import './index.css';
import { NavLink, useLocation } from "react-router-dom";
import { Listing } from "./views/Listing";
import { PageNotFound } from "./views/PageNotFound";
import { SplashScreen } from "./components/SplashScreen";
import TagManager from 'react-gtm-module';


const WrapperView = () => {
  const [AppState, AppDispatch] = useReducer(AppReducer, AppInitState);
  const [openProfileDropDown, setOpenProfileDropDown] = useState(false);
  const [openLanguage, setOpenLanguage] = useState(false);
  const [topNavValue, setTopNavValue] = useState("SHOPPING_AREA"); //useState("BUSINESS_AREA")
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [t, i18n] = useTranslation("common");
  const tagManagerArgs = {
    gtmId: 'GTM-5BZCF55G'
  }

  TagManager.initialize(tagManagerArgs)

  const { loggedIn, dir, language } = AppState;

  const changeTopNavHandler = (value) => {
    setTopNavValue(value)
  }
  const changeLanguageHandler = (value) => {
    i18n.changeLanguage(value);
    AppDispatch({ type: "language", payload: { language: value } });
  };


  const handleLoading = () => {
    setIsLoading(false);
  }

  useEffect(() => {
    window.addEventListener("load", handleLoading);
    return () => window.removeEventListener("load", handleLoading);
  }, [])
  return (
    <div className="roshnfront" dir={dir}>
      {
        (location.pathname === "/get-in-touch" || location.pathname === "/listing") &&
        <Header topNavValue={topNavValue} location={location.pathname} changeTopNavHandler={changeTopNavHandler} language={language}
          changeLanguageHandler={(value) => changeLanguageHandler(value)} />
      }

      <Switch>
        <Route exact path="/">
          <ShoppingArea topNavValue={topNavValue} language={language}
            changeLanguageHandler={(value) => changeLanguageHandler(value)} changeTopNavHandler={changeTopNavHandler} />
        </Route>
        <Route path="/business-area">
          <BusinessArea language={language}
            changeLanguageHandler={(value) => changeLanguageHandler(value)} changeTopNavHandler={changeTopNavHandler} />
        </Route>
        <Route path="/listing">
          <Listing language={language}
            changeLanguageHandler={(value) => changeLanguageHandler(value)} topNavValue={topNavValue} changeTopNavHandler={changeTopNavHandler} />
        </Route>
        <Route path="/get-in-touch">
          <Contact topNavValue={topNavValue} language={language} />
        </Route>
        <Route path="/about">
          <About language={language}
            changeLanguageHandler={(value) => changeLanguageHandler(value)} />
        </Route>
        <Route path="*">
          <PageNotFound />
        </Route>
        {/* <Route>
     <NoMatch />
   </Route> */}
      </Switch>
      {/* <Footer /> */}
    </div>

  )
};
const App = () => {
  const [AppState, AppDispatch] = useReducer(AppReducer, AppInitState);
  const { token } = AppState;

  clarity.init('mlmmdldb2u');

  useEffect(() => {
    const tagManagerArgs = {
      gtmId:
        'GTM-5BZCF55G', // Replace with your GTM container ID
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <AppContext.Provider value={{ AppState, AppDispatch }}>
      <Router>
        <WrapperView />
      </Router>
    </AppContext.Provider>
  );
};

export default App;
