import React, { useState } from 'react'

import { CiMenuBurger } from "react-icons/ci";
import { CiLocationOn } from "react-icons/ci";
import { BsHeadset } from "react-icons/bs";
import { PiTwitterLogoLight } from "react-icons/pi";
import { AiOutlineFacebook } from "react-icons/ai";
import { AiOutlineInstagram } from "react-icons/ai";
import { AiOutlineYoutube } from "react-icons/ai";
import Background from '../../assets/images/get-in-touch-bg.png';
import BrandPatternDot from '../../assets/images/brand-pattren-(m).svg'

import { useTranslation } from "react-i18next";
import { ListingBusinessDesktop } from '../../components/ListingDesktop/LisitngBusinessDesktop';
import { ListingShoppingDesktop } from '../../components/ListingDesktop/ListingShoppingDesktop';
import { ListingShoppingMobile } from '../../components/ListingDesktop/ListingShoppingMobile';
import { LisitngBusinessMobile } from '../../components/ListingDesktop/ListingBusinessMobile';

export const Listing = ({ lang, topNavValue, changeTopNavHandler }) => {
    const [t, i18n] = useTranslation("common");

    const [openDropdown, setOpenDropdown] = useState(false);
    const [dropDownItem, setDropDownItem] = useState('');

    const openDropdownHandler = (e) => {
        setOpenDropdown(!openDropdown);
    };

    const changeDropDownHandler = (e) => {
        setDropDownItem(e.target.value)
    }


    return (
        <main className='mt-8'>
            {/* Start Desktop version */}
            <div className='hidden md:block'>
                {
                    topNavValue === "SHOPPING_AREA" ?
                        <ListingShoppingDesktop topNavValue={topNavValue} changeTopNavHandler={changeTopNavHandler} />
                        :
                        <ListingBusinessDesktop topNavValue={topNavValue} changeTopNavHandler={changeTopNavHandler} />
                }
            </div>
            {/* End desktop version */}
            <div className='block md:hidden'>
                {
                    topNavValue === "SHOPPING_AREA" ?
                        <ListingShoppingMobile topNavValue={topNavValue} />
                        :
                        <LisitngBusinessMobile topNavValue={topNavValue} />
                }
            </div>
        </main>
    )
}


