import React, { useState, useRef } from 'react'
import { axiosInstance } from "../../axios";
import ReCAPTCHA from "react-google-recaptcha";

import { CiMenuBurger } from "react-icons/ci";
import { CiLocationOn } from "react-icons/ci";
import { BsHeadset } from "react-icons/bs";
import { AiOutlineArrowRight, AiOutlineFacebook } from "react-icons/ai";
import { AiOutlineInstagram } from "react-icons/ai";
import { AiOutlineYoutube } from "react-icons/ai";
import Background from '../../assets/images/get-in-touch-bg.png';
import BrandPatternDot from '../../assets/images/brand-pattren-(m).svg'
import ConfirmationIcon from '../../assets/images/icons/confirmation-icon.svg'

import { useTranslation } from "react-i18next";
import { MdArrowBack, MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md';
import { RiTwitterXFill } from 'react-icons/ri';
import { log } from '@craco/craco/lib/logger';

export const Contact = ({ lang, topNavValue }) => {
    const [t, i18n] = useTranslation("common");
    const CONTACT_URL = "/contact-us/";


    const [openDropdown, setOpenDropdown] = useState(false);
    const [requestType, setRequestType] = useState('SUGGESTION');
    const [projectType, setProjectType] = useState('');
    const [unitType, setUnitType] = useState(null);
    const [inputs, setInputs] = useState({});
    const [loading, setLoading] = useState(false);
    const [successSubmit, setSuccessSubmit] = useState(false);
    const [standardErrorMessage, setStandardErrorMessage] = useState(false);
    const [errors, setErrors] = useState({});
    const [submitting, setSubmitting] = useState(false);

    const captchaRef = React.createRef();


    const openDropdownHandler = (e) => {
        setOpenDropdown(!openDropdown);
    };

    const changeRequestTypeHandler = (e) => {
        setRequestType(e.target.value)
    }

    const changeUnitTypeHandler = (e) => {
        setUnitType(e.target.value)
    }

    const handleInputsChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }

    const recaptchOnChnage = (value) => {
        console.log("recaptchOnChnage... ", "...")
    }

    const handleSubmit = async (event) => {
        setLoading(true)
        event.preventDefault();

        const recaptchaValue = await captchaRef.current.executeAsync();

        axiosInstance
            .post(CONTACT_URL, {
                captcha: recaptchaValue,
                name: inputs.name,
                email: inputs.email,
                mobile: "966" + inputs.mobileNumber,
                subject: requestType,
                message: inputs.message,
                area: topNavValue,
                requiredUnitType: unitType,
                requiredUnitSpace: inputs.requiredUnitSpace,
                commercialName: inputs.commercialName,
                activityType: inputs.activityType,
                numberOfBranchsInKSA: inputs.branchInsideKsa,
                numberOfBranchsOutsideKSA: inputs.branchOutsideKsa,
                website: inputs.website,
                socialMedia: inputs.socialMedia
            })
            .then(res => {
                setLoading(false);
                setSuccessSubmit(true);
                setStandardErrorMessage(false)
            })
            .catch(err => {
                setLoading(false);
                setStandardErrorMessage(true)
                // Show Standard Error Message
            });
    }

    const newInqueryHandler = () => {
        setSuccessSubmit(false);

    }

    const validateValues = (event) => {
        event.preventDefault();
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputs.email)) {
            setErrors({ email: "The email format is incorrect" })
        }
        else if (!/^(009665|9665|\+9665|5|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/.test(inputs.mobileNumber)) {
            setErrors({ mobile: "Somthing is wrong!" })

        }
        else {
            setErrors({})
            handleSubmit(event)
        }
    };

    return (
        <main className={`flex flex-wrap `}  >
            <div className="relative hidden w-full h-screen md:w-1/2 md:block" style={{ backgroundImage: `url(${Background})`, backgroundSize: 'cover' }} >
                <div className='absolute flex mt-40 px-14'>
                    <div className='text-white'>
                        <div className={`flex items-baseline space-x-2 flex-nowrap ${i18n.language === 'en' ? 'space-x-2 sm:justify-start' : 'space-x-reverse space-x-2 sm:justify-start'}`}>
                            <img
                                alt="pattern"
                                src={BrandPatternDot}
                                width={10}
                                height={10}
                                color='#fffff'
                            />
                            <h3 className={`text-base font-light uppercase ${i18n.language === 'en' ? 'tracking-widestExtra text-left' : 'text-right'}`}>{t('form.smallTitle')}</h3>
                        </div>
                        <h1 className='my-12 text-6xl font-light leading-4 '>{t("form.mainTitle")}</h1>
                        <div className=''>
                            <div className={`relative mb-8 backdrop-blur-3xl bg-opacity-20 bg-primary-50  bg-transparent text-white py-4 px-10 rounded-md text-sm`}>
                                <h1>{t("form.CustomerSupport")}</h1>
                                <span>920019339</span>
                                <div className={`absolute ${i18n.language === "en" ? "-left-4 " : "-right-4 "} top-4 p-2 rounded-md bg-white bg-opacity-60`}>
                                    <BsHeadset className='w-6 h-6 text-natural-secondary-600' />
                                </div>
                            </div>
                            <div className={`relative mb-8 backdrop-blur-3xl bg-opacity-20 bg-primary-50  bg-transparent text-white py-4 px-10 rounded-md text-sm`}>
                                <h1>{t('footer.location1')}</h1>
                                <span>{t('footer.location2')}</span>
                                <div className={`absolute ${i18n.language === "en" ? "-left-4 " : "-right-4 "} top-4 p-2 rounded-md bg-white bg-opacity-60`}>
                                    <CiLocationOn className='w-6 h-6 text-natural-secondary-600' />
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <div className='absolute left-0 right-0 flex w-48 gap-2 ml-auto mr-auto bottom-6'>
                    <a target='_blank' href='https://twitter.com/ROSHNFrontKSA' className='p-2 bg-opacity-50 rounded-md bg-natural-secondary-400'>
                        <RiTwitterXFill className='w-6 h-6 text-white' />
                    </a>
                    <a target='_blank' href='https://www.facebook.com/RoshnFrontKsa/' className='p-2 bg-opacity-50 rounded-md bg-natural-secondary-400'>
                        <AiOutlineFacebook className='w-6 h-6 text-white' />
                    </a>
                    <a target='_blank' href='https://www.instagram.com/RoshnFrontKsa' className='p-2 bg-opacity-50 rounded-md bg-natural-secondary-400'>
                        <AiOutlineInstagram className='w-6 h-6 text-white' />
                    </a>
                </div >
            </div >
            <div className="w-full p-8 overflow-y-scroll md:w-1/2" style={{ height: "800px" }}>
                <div className={`${Object.keys(errors).length === 0 && successSubmit ? 'block' : 'hidden'} flex flex-col items-center justify-center pt-40`}>

                    <img
                        alt="icon"
                        src={ConfirmationIcon}
                        className='my-4'
                    />
                    <div className='text-center'>
                        <p className='pb-4 text-2xl border-b text-natural-200 border-natural-secondary-200'>{t("form.successMessage")}</p>
                        <div onClick={newInqueryHandler} className='flex items-center justify-center gap-2 mt-4 cursor-pointer'>
                            <p className={`text-base font-semibold text-natural-900 ${i18n.language === "ar" ? "mt-1.5" : ""}`}>{t("form.newInquery")} </p>
                            {
                                i18n.language === "en" ? (
                                    <MdArrowForwardIos className="w-5 h-5 ml-2 font-semibold " />

                                ) : (
                                    <MdArrowBackIos className="w-5 h-5 mt-1 font-semibold" />
                                )
                            }
                        </div>
                    </div>
                </div>
                <form onSubmit={validateValues
                } className={`form-group ${Object.keys(errors).length === 0 && successSubmit ? 'hidden' : 'block'}`}>

                    {/* <label for="name" class="block text-base font-semibold leading-6 text-natural-secondary-900 placeholder:text-natural-secondary-400">Name</label> */}
                    <div className="mt-2.5">
                        <input name="name" id="name" required onChange={handleInputsChange} className="block w-full rounded-md border-0 px-3.5 py-2 appearance-none	 text-natural-secondary-900 placeholder:text-natural-secondary-400 shadow-sm ring-1 ring-inset ring-natural-secondary-200   focus:ring-1 focus:ring-inset focus:ring-natural-secondary-400 focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-natural-secondary-400 sm:text-sm sm:leading-6" value={inputs.name || ""} placeholder={t('form.name')} type="text" maxLength="30" data-rule-preventtags="true" autoComplete="off" error="Please provide your first name" />
                        {errors.name ? (
                            i18n.language === "en" ?
                                <p className="error">
                                    name should be at least 15 characters long
                                </p>
                                :
                                <p className="error">
                                    الاسم يجب ان يكون اقل من ١٥ حرف
                                </p>
                        ) : null}
                    </div>
                    {/* <label for="name" className="block text-base font-semibold leading-6 text-natural-secondary-900 placeholder:text-natural-secondary-400">Name</label> */}
                    <div className="mt-10">
                        <input required onChange={handleInputsChange} value={inputs.email || ""} placeholder={t('form.email')} type="text" name="email" id="email" autoComplete="off" className="block w-full rounded-md border-0  appearance-none px-3.5 py-2 text-natural-secondary-900 placeholder:text-natural-secondary-400 shadow-sm ring-1 ring-inset ring-natural-secondary-200  focus:ring-1 focus:ring-inset focus:ring-natural-secondary-400 focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-natural-secondary-400 sm:text-sm sm:leading-6" />
                        {errors.email ? (
                            <p className="mt-2 text-sm text-red-600">
                                {t("formErros.email")}
                            </p>
                        ) : null}
                    </div>
                    {/* <label for="name" className="block text-base font-semibold leading-6 text-natural-secondary-900 placeholder:text-natural-secondary-400">Name</label> */}
                    <div className="mt-10">
                        <input required onChange={handleInputsChange} value={inputs.mobileNumber || ""} placeholder={t('form.mobileNumber')} type="text" name="mobileNumber" id="mobileNumber" autoComplete="off" maxLength="9" className="block w-full rounded-md border-0  appearance-none px-3.5 py-2 text-natural-secondary-900 shadow-sm ring-1 ring-inset ring-natural-secondary-200  placeholder:text-natural-secondary-400 focus:ring-1 focus:ring-inset focus:ring-natural-secondary-400 focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-natural-secondary-400 sm:text-sm sm:leading-6" />
                        {errors.mobile ? (
                            <p className="mt-2 text-sm text-red-600">
                                {t("formErros.mobile")}
                            </p>
                        ) : null}
                    </div>
                    {/* Subject */}
                    <div className="relative inline-block w-full mt-10 dropdown">
                        <select value={requestType} onChange={(event) => changeRequestTypeHandler(event)} name="subject" onClick={(e) => openDropdownHandler(e)} className="flex items-center justify-between w-full px-4 py-1 mr-1 text-sm border rounded shadow-sm text-natural-secondary-400 placeholder:text-natural-secondary-400 border-natural-secondary-200 ">
                            <option value="SUGGESTION" color='#a1a1aa' className="block px-4 py-2 whitespace-no-wrap rounded-b hover:bg-natural-secondary-100 hover:text-natural-secondary-900 focus:ring-1 focus:ring-inset focus:ring-natural-secondary-400">{t('form.inquireType.visitors')}</option>
                            <option value="BUSINESSS_OPPORTUNITY" color='#a1a1aa' className="block px-4 py-2 whitespace-no-wrap hover:bg-natural-secondary-100 hover:text-natural-secondary-900 focus:ring-1 focus:ring-inset focus:ring-natural-secondary-400">{t('form.inquireType.opportunity')}</option>
                            <option value="EVENT" color='#a1a1aa' className="block px-4 py-2 whitespace-no-wrap rounded-b hover:bg-natural-secondary-100 hover:text-natural-secondary-900 focus:ring-1 focus:ring-inset focus:ring-natural-secondary-400">{t('form.inquireType.Events')}</option>
                            <option value="ADVERTISMENT" color='#a1a1aa' className="block px-4 py-2 whitespace-no-wrap rounded-b hover:bg-natural-secondary-100 hover:text-natural-secondary-900 focus:ring-1 focus:ring-inset focus:ring-natural-secondary-400">{t('form.inquireType.media')}</option>
                            <option value="LEASE" color='#a1a1aa' className="block px-4 py-2 whitespace-no-wrap rounded-b hover:bg-natural-secondary-100 hover:text-natural-secondary-900 focus:ring-1 focus:ring-inset focus:ring-natural-secondary-400">{t('form.inquireType.leasing')}</option>
                        </select>
                    </div>
                    {/* Leasing part */}
                    {
                        requestType === "LEASE" && (
                            <div className='mt-10'>
                                <h1 className='text-lg text-natural-secondary-400 tracking-[.24em] uppercase'>{t('form.business-info')}</h1>
                                <div className="mt-10">
                                    <input required onChange={handleInputsChange} value={inputs.brandName || ""} placeholder={t('form.brandName')} type="text" name="brandName" id="brandName" autoComplete="off" className="block w-full rounded-md border-0  appearance-none	 px-3.5 py-2 text-natural-secondary-900 shadow-sm ring-1 ring-inset ring-natural-secondary-200  placeholder:text-natural-secondary-400 focus:ring-1 focus:ring-inset focus:ring-natural-secondary-400 focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-natural-secondary-400 sm:text-sm sm:leading-6" />
                                </div>
                                <div className="mt-10">
                                    <input onChange={handleInputsChange} value={inputs.branchInsideKsa || ""} placeholder={t('form.ksaBarnchsInside')} min="0" type="number" name="branchInsideKsa" id="branchInsideKsa" autoComplete="off" className="block w-full rounded-md border-0  appearance-none px-3.5 py-2 text-natural-secondary-900 shadow-sm ring-1 ring-inset ring-natural-secondary-200  placeholder:text-natural-secondary-400 focus:ring-1 focus:ring-inset focus:ring-natural-secondary-400 focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-natural-secondary-400 sm:text-sm sm:leading-6" />
                                </div>
                                <div className="mt-10">
                                    <input onChange={handleInputsChange} value={inputs.branchOutsideKsa || ""} placeholder={t('form.ksaBarnchsOutside')} min="0" type="number" name="branchOutsideKsa" id="branchOutsideKsa" autoComplete="off" className="block w-full rounded-md border-0  appearance-none px-3.5 py-2 text-natural-secondary-900 shadow-sm ring-1 ring-inset ring-natural-secondary-200  placeholder:text-natural-secondary-400 focus:ring-1 focus:ring-inset focus:ring-natural-secondary-400 focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-natural-secondary-400 sm:text-sm sm:leading-6" />
                                </div>
                                <div className='mt-10'>
                                    <h1 className='text-lg text-natural-secondary-400 tracking-[.24em] uppercase'>{t('form.project-info')}</h1>
                                    <div className="relative inline-block w-full mt-10 dropdown">
                                        <select value={unitType} onChange={(event) => changeUnitTypeHandler(event)} className="flex items-center justify-between w-full px-4 py-1 mr-1 text-sm border rounded shadow-sm text-natural-secondary-400 placeholder:text-natural-secondary-400 border-natural-secondary-200 ">
                                            <option color='#a1a1aa' className="block px-4 py-2 whitespace-no-wrap rounded-b hover:bg-natural-secondary-100 hover:text-natural-secondary-900 focus:ring-1 focus:ring-inset focus:ring-natural-secondary-400">{t('form.unitType')}</option>
                                            <option color='#a1a1aa' value="STORE" className="block px-4 py-2 whitespace-no-wrap rounded-b hover:bg-natural-secondary-100 hover:text-natural-secondary-900 focus:ring-1 focus:ring-inset focus:ring-natural-secondary-400">{t('form.store')}</option>
                                            <option color='#a1a1aa' value="KISOK" className="block px-4 py-2 whitespace-no-wrap rounded-b hover:bg-natural-secondary-100 hover:text-natural-secondary-900 focus:ring-1 focus:ring-inset focus:ring-natural-secondary-400">{t('form.kiosk')}</option>
                                            <option color='#a1a1aa' value="OFFICE" className="block px-4 py-2 whitespace-no-wrap rounded-b hover:bg-natural-secondary-100 hover:text-natural-secondary-900 focus:ring-1 focus:ring-inset focus:ring-natural-secondary-400">{t('form.office')}</option>
                                        </select>
                                    </div>
                                    <div className="mt-10">
                                        <input onChange={handleInputsChange} value={inputs.activityType || ""} placeholder={t('form.activityType')} type="text" name="activityType" id="activityType" autoComplete="off" className="block w-full rounded-md border-0  appearance-none px-3.5 py-2 text-natural-secondary-900 shadow-sm ring-1 ring-inset ring-natural-secondary-200  placeholder:text-natural-secondary-400 focus:ring-1 focus:ring-inset focus:ring-natural-secondary-400 focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-natural-secondary-400 sm:text-sm sm:leading-6" />
                                    </div>
                                    <div className="mt-10">
                                        <input onChange={handleInputsChange} value={inputs.spaceSqm || ""} placeholder={t('form.space')} type="text" name="spaceSqm" id="spaceSqm" autoComplete="off" className="block w-full rounded-md border-0  appearance-none px-3.5 py-2 text-natural-secondary-900 shadow-sm ring-1 ring-inset ring-natural-secondary-200  placeholder:text-natural-secondary-400 focus:ring-1 focus:ring-inset focus:ring-natural-secondary-400 focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-natural-secondary-400 sm:text-sm sm:leading-6" />
                                    </div>
                                </div>
                                <div className='mt-10'>
                                    <h1 className='text-lg text-natural-secondary-400 tracking-[.24em] uppercase'>{t('form.additionalInfo')}</h1>

                                    <div className="mt-10">
                                        <input onChange={handleInputsChange} value={inputs.website || ""} placeholder={t('form.website')} type="text" name="website" id="website" autoComplete="off" className="block w-full rounded-md border-0  appearance-none px-3.5 py-2 text-natural-secondary-900 shadow-sm ring-1 ring-inset ring-natural-secondary-200  placeholder:text-natural-secondary-400 focus:ring-1 focus:ring-inset focus:ring-natural-secondary-400 focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-natural-secondary-400 sm:text-sm sm:leading-6" />
                                    </div>
                                    <div className="mt-10">
                                        <input onChange={handleInputsChange} value={inputs.socialMedia || ""} placeholder={t('form.socialMedia')} type="text" name="socialMedia" id="socialMedia" autoComplete="off" className="block w-full rounded-md border-0  appearance-none px-3.5 py-2 text-natural-secondary-900 shadow-sm ring-1 ring-inset ring-natural-secondary-200  placeholder:text-natural-secondary-400 focus:ring-1 focus:ring-inset focus:ring-natural-secondary-400 focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-natural-secondary-400 sm:text-sm sm:leading-6" />
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    <div className="mt-10">
                        <textarea required onChange={handleInputsChange} value={inputs.message || ""} placeholder={t('form.message')} type="text" name="message" id="message" autoComplete="off" className="block w-full rounded-md border-0  appearance-none	 px-3.5 py-2 text-natural-secondary-900  shadow-sm ring-1 ring-inset ring-natural-secondary-200  placeholder:text-natural-secondary-400 focus:ring-1 focus:ring-inset focus:ring-natural-secondary-400 focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-natural-secondary-400 sm:text-sm sm:leading-6" />
                    </div>
                    <button className='w-full px-2 pt-3 pb-2 mt-10 text-white rounded-md bg-primary-600 hover:bg-primary-800'>
                        {loading ? (
                            <div className="inline-block w-6 h-6 mx-2 border-4 border-white border-dashed rounded-full animate-spin"></div>
                        ) : (
                            <span>{t('form.submit')}</span>
                        )}
                    </button>

                    {standardErrorMessage && (
                        <p className='mt-4 text-lg font-semibold text-primary-900'>{t('form.standardErrorMessage')} </p>
                    )}

                    <ReCAPTCHA
                        ref={captchaRef}
                        size="invisible"
                        sitekey="6LflKdQkAAAAAJIZMSfl4IJSprb3hF_2aJMCLQV-"
                        badge={'bottomright'}
                        onChange={recaptchOnChnage}
                    />
                </form>
            </div>
        </main >
    )
}


