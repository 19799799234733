import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import CorpVideo from '../../assets/videos/about-us-mandate.mp4'
import { PiPlay } from 'react-icons/pi';
import { BsFillPlayFill } from 'react-icons/bs';


export const CorpVid = () => {
    const [t, i18n] = useTranslation("common");
    const [videoPlayer, setVideoPlayer] = useState(null);

    const playVideo = () => {
        videoPlayer.play();
    };


    return (
        <section className="relative flex items-center justify-center h-screen overflow-hidden mt-14">
            <video className="absolute z-10 w-auto max-w-none sm:min-w-full sm:min-h-full" autoPlay muted loop playsInline>
                <source src={CorpVideo} type="video/mp4" />
            </video>

            {/* <div className="absolute top-1/2 left-1/2 transform translate-x-[-50%] translate-y-[-50%] z-50 section">
                    {videoPlayer &&
                        <button
                            className="z-50 p-4 text-lg font-semibold text-black rounded-full bg-primary-800"
                            onClick={playVideo}
                        >
                            <BsFillPlayFill className='w-10 h-10 text-white' />
                        </button>
                    }

                </div> */}

        </section>
    );
};
