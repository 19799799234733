import React, { useState } from 'react'
import { useTranslation } from "react-i18next";

import { BsArrowLeftShort } from "react-icons/bs";
import { FaHeadset } from "react-icons/fa";
import { MdKeyboardArrowDown } from "react-icons/md";

export const StickyTootip = () => {
    const [t, i18n] = useTranslation("common");
    const [openToolTip, setopenToolTip] = useState(false);

    const openToolTipHandler = () => {
        setopenToolTip(!openToolTip);
    }

    return (
        <div className={`absolute z-50 top-24 bg-secondary-100 py-1 px-4 rounded-full hidden lg:block ${i18n.language === 'en' ? 'right-14' : 'left-10'}`}>
            <button onClick={openToolTipHandler} className="flex gap-2 pt-1 cursor-pointer">
                <h4 className="text-sm">
                    {t("stickyTitle")}
                </h4>
                <MdKeyboardArrowDown className="w-4 h-4" />
            </button>
            <div className={`absolute top-12 ${i18n.language === 'en' ? 'right-0' : 'left-0'} ${openToolTip ? "block" : "hidden"}`}>
                <div className='flex gap-4 md:gap-2'>
                    <div className='p-4 text-center bg-opacity-50 rounded-md bg-primary-50 backdrop-blur-3xl drop-shadow-lg'>
                        <span className='block'>{t('days.sat')}</span>
                        <span className='block'>10:00</span>
                        <span className='block'>02:00</span>
                    </div>
                    <div className='p-4 text-center bg-opacity-50 rounded-md bg-primary-50 backdrop-blur-3xl drop-shadow-lg'>
                        <span className='block'>{t('days.sun')}</span>
                        <span className='block'>10:00</span>
                        <span className='block'>02:00</span>
                    </div>
                    <div className='p-4 text-center bg-opacity-50 rounded-md bg-primary-50 backdrop-blur-3xl drop-shadow-lg'>
                        <span className='block'>{t('days.mon')}</span>
                        <span className='block'>10:00</span>
                        <span className='block'>02:00</span>
                    </div>
                    <div className='p-4 text-center bg-opacity-50 rounded-md bg-primary-50 backdrop-blur-3xl drop-shadow-lg'>
                        <span className='block'>{t('days.tus')}</span>
                        <span className='block'>10:00</span>
                        <span className='block'>02:00</span>
                    </div>
                    <div className='p-4 text-center bg-opacity-50 rounded-md bg-primary-50 backdrop-blur-3xl drop-shadow-lg'>
                        <span className='block'>{t('days.wed')}</span>
                        <span className='block'>10:00</span>
                        <span className='block'>02:00</span>
                    </div>
                    <div className='p-4 text-center bg-opacity-50 rounded-md bg-primary-50 backdrop-blur-3xl drop-shadow-lg'>
                        <span className='block'>{t('days.thu')}</span>
                        <span className='block'>10:00</span>
                        <span className='block'>02:00</span>
                    </div>
                    <div className='p-4 text-center bg-opacity-50 rounded-md bg-primary-50 backdrop-blur-3xl drop-shadow-lg'>
                        <span className='block'>{t('days.fri')}</span>
                        <span className='block'>10:00</span>
                        <span className='block'>02:00</span>
                    </div>

                </div>
            </div>
        </div>
    );
};
