import React from "react";
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BsArrowLeftShort, BsInstagram } from "react-icons/bs";
import { FaHeadset } from "react-icons/fa";
import { GoLocation } from "react-icons/go";

import FooterBg from '../../assets/images/footer-bg.png';
import LogoFooter from '../../assets/images/Logo-footer.svg';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { RiTwitterXFill, RiYoutubeLine } from "react-icons/ri";
import { LiaFacebookSquare } from "react-icons/lia";

export const Footer = ({ topNavValue }) => {
  const [t, i18n] = useTranslation("common");
  AOS.init();

  return (
    <footer data-aos="fade-up" data-aos-delay="100"
      data-aos-duration="500" className={`md:p-10 relative md:mt-14 ${i18n.language === 'en' ? 'bg-left' : 'bg-right'}`}>
      <div className={`flex flex-wrap justify-between py-6 ${topNavValue === "SHOPPING_AREA" ? 'bg-white' : ''}   md:flex-nowrap rounded-2xl`}>
        <div className='w-full px-4 md:px-16'>
          <a target="_blank" href="https://maps.app.goo.gl/5brDGgD5kRQAx3KD6" className='flex items-center justify-center w-full px-2 pt-3 pb-2 mt-10 space-x-2 bg-white border rounded-md text-natural-900 border-natural-900 hover:bg-natural-secondary-100'>
            <GoLocation className={`h-6 w-6 ${i18n.language === 'en' ? 'order-0' : 'order-1'}`} />  <strong className='pt-1 text-base'>{t('directions')}</strong>
          </a>

          <div className='mt-12'>
            <h1 className='text-xl'>
              <strong>{t('footer.openningHours')}</strong>
            </h1>
            <div className='flex flex-wrap gap-4 mt-4 md:gap-2'>
              <div className='p-4 text-xs text-center border rounded-md lg:text-base border-natural-900'>
                <span className='block'>{t('days.sat')}</span>
                <span className='block'>10:00</span>
                <span className='block'>02:00</span>
              </div>
              <div className='p-4 text-xs text-center border rounded-md lg:text-base border-natural-900'>
                <span className='block'>{t('days.sun')}</span>
                <span className='block'>10:00</span>
                <span className='block'>02:00</span>
              </div>
              <div className='p-4 text-xs text-center border rounded-md lg:text-base border-natural-900'>
                <span className='block'>{t('days.mon')}</span>
                <span className='block'>10:00</span>
                <span className='block'>02:00</span>
              </div>
              <div className='p-4 text-xs text-center border rounded-md lg:text-base border-natural-900'>
                <span className='block'>{t('days.tus')}</span>
                <span className='block'>10:00</span>
                <span className='block'>02:00</span>
              </div>
              <div className='p-4 text-xs text-center border rounded-md lg:text-base border-natural-900'>
                <span className='block'>{t('days.wed')}</span>
                <span className='block'>10:00</span>
                <span className='block'>02:00</span>
              </div>
              <div className='p-4 text-xs text-center border rounded-md lg:text-base border-natural-900'>
                <span className='block'>{t('days.thu')}</span>
                <span className='block'>10:00</span>
                <span className='block'>02:00</span>
              </div>
              <div className='p-4 text-xs text-center border rounded-md lg:text-base border-natural-900'>
                <span className='block'>{t('days.fri')}</span>
                <span className='block'>10:00</span>
                <span className='block'>02:00</span>
              </div>

            </div>
          </div>
          <div className='flex justify-center mt-12'>
            <Link to="/">
              <img
                alt="RoshnFront Logo"
                src={LogoFooter}
                // color='#2A1815'
                width={300}
                height={300}
              />
            </Link>
          </div>
          <div className='my-6 text-center'>
            {t('footer.roshnBrief')}
          </div>
        </div>

        <div className={`w-full mt-10 px-4 md:px-16 ${i18n.language === "en" ? 'border-l' : 'border-r'} border-natural-secondary-300`}>
          <h1 className='text-xl'>
            <strong>{t('footer.stayConnected')}</strong>
          </h1>
          <div className='mt-6'>
            {t('footer.mailLisitngBody')}
          </div>

          <div className='mt-5'>
            <Link onClick={() => window.scrollTo(0, 0)} to="/about" className='flex justify-between p-4 border-b border-natural-50 md:border-b-0 md:bg-natural-50'>
              <p>{t('footer.aboutRoshnFront')}</p>
              {i18n.language === "en" ? (
                <IoIosArrowForward className='w-5 h-5' />
              ) : (
                <IoIosArrowBack className='w-5 h-5' />
              )}
            </Link>
            <Link onClick={() => window.scrollTo(0, 0)} to="/get-in-touch" className='flex justify-between p-4 my-1 border-b border-natural-50 md:border-b-0 md:bg-natural-50'>
              <p>{t('footer.contactUs')}</p>
              {i18n.language === "en" ? (
                <IoIosArrowForward className='w-5 h-5' />
              ) : (
                <IoIosArrowBack className='w-5 h-5' />
              )}
            </Link>

            <a target="_blank" href="https://wayfinding.roshn.sa/amap/map.php?site=ROSHNFRONT" className='flex justify-between p-4 border-b border-natural-50 md:border-b-0 md:bg-natural-50'>
              <p>{t('footer.map')}</p>
              {i18n.language === "en" ? (
                <IoIosArrowForward className='w-5 h-5' />
              ) : (
                <IoIosArrowBack className='w-5 h-5' />
              )}
            </a>
          </div>
        </div>
      </div>

      <div className='flex flex-wrap items-center justify-between p-4 my-8 md:p-0'>
        <div className='flex flex-wrap gap-4'>
          <p className='text-sm'>
            {t('footer.COPYRIGHT')}
          </p>
          {/* <Link to="/" className="text-sm underline capitalize">
                {t('footer.privacyPolicy')}
            </Link> */}
        </div>

        <div className='flex flex-wrap items-center gap-6 '>
          <div>
            <h1>{t('footer.customerSupport')}
            </h1>
            <p>
              920019339
            </p>
          </div>

          <div>
            <h1>
              {t('footer.location1')}
            </h1>
            <p>
              {t('footer.location2')}
            </p>
          </div>

          <div className='flex gap-2'>
            <a target="_blank" href="https://twitter.com/ROSHNFrontKSA" className='p-2 bg-white rounded-md'>
              <RiTwitterXFill className='w-5 h-5' />
            </a>
            <a target="_blank" href="https://www.facebook.com/RoshnFrontKsa/" className='p-2 bg-white rounded-md'>
              <LiaFacebookSquare className='w-6 h-6' />
            </a>
            <a target="_blank" href="https://www.instagram.com/RoshnFrontKsa" className='p-2 bg-white rounded-md'>
              <BsInstagram className='w-6 h-6' />
            </a>

          </div>
        </div>
      </div>
    </footer>
  );
};
