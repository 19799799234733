import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { CiMenuBurger } from "react-icons/ci";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { BsChevronLeft, BsChevronRight, BsInstagram } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import EventsBgEn from '../../assets/images/events-bg-en.svg';
import EventsBgAr from '../../assets/images/events-bg-ar.svg';
import shopsBgEn from '../../assets/images/shops-bg-en.svg';
import shopsBgAr from '../../assets/images/shops-bg-ar.svg';
import events2BgEn from '../../assets/images/events2-bg-en.svg';
import events2BgAr from '../../assets/images/events2-bg-ar.svg';
import FooterBg from '../../assets/images/footer-bg.png';
import NavBarBg from '../../assets/images/navbar-bg-outline.svg';
import LogoFooter from '../../assets/images/Logo-footer.svg';

import VideoLayerBg from '../../assets/images/shopping-area-video-layer.svg';

import RoshnFrontLogo from "../../assets/images/roshnfront-logo.svg"
import BrandPatternDot from '../../assets/images/brand-pattren-(m).svg'

import BusinessAreaBg from '../../assets/images/RF-night-bg.jpg'

import BrandPatternDotBlack from '../../assets/images/brand-pattren-black.svg'
import CommunityIcon from '../../assets/images/icons/community-icon.svg'
import communityIconColor from '../../assets/images/icons/community-icon-1.svg'
import DineIcon from '../../assets/images/icons/dine-icon.svg'
import elevatorIcon from '../../assets/images/icons/elevator-icon.svg'
import CalendarIcon from '../../assets/images/icons/calendar-icon-color.svg'
import VistiorsIcon from '../../assets/images/icons/visitor-icon.svg'
import FoodIcon from '../../assets/images/icons/food-icon.svg'
import ServicesIcon from '../../assets/images/icons/services-icon.svg'
import EventsIcon from '../../assets/images/icons/calendar-icon.svg'
import SimpleSlider from '../../components/SimpleSlider/SimpleSlider';
import { AiOutlineUnorderedList, AiOutlineCalendar, AiOutlineClose, AiOutlineLock, AiOutlineSearch } from "react-icons/ai";
import { IoIosArrowBack, IoIosArrowDown, IoIosArrowDropup, IoIosArrowForward, IoIosArrowUp } from "react-icons/io";
import { LiaFacebookSquare, LiaOilCanSolid, LiaShoppingBagSolid } from "react-icons/lia";
import { BiDrink, BiWine, BiLogoSquarespace, BiSearchAlt } from "react-icons/bi";
import { Header } from '../../components/Header';
import Slider2 from '../../components/SimpleSlider/Slider-2';
import { MdMiscellaneousServices } from "react-icons/md";
import { FiHeadphones, FiSmile } from "react-icons/fi";
import { RiCarWashingLine, RiTwitterXFill, RiYoutubeLine } from "react-icons/ri";
import { StickyTootip } from '../../components/StickyTooltip/StickyTooltip';
import EventsSliderEn from '../../components/BusinessAreaSliders/EventsSliderEn';
import EventsSliderAr from '../../components/BusinessAreaSliders/EventsSliderAr';
import ThriveSection from './ThriveSection';
import { Footer } from '../../components/Footer/Footer';
import WhatsNewSection from '../../components/SimpleSlider/WhatsNewSection/WhatsNewSection';

export const BusinessArea = ({ changeLanguageHandler, language, changeTopNavHandler }) => {
    const [openSideNav, setOpenSideNav] = useState(false);
    const [activeTab, setActiveTab] = useState("cinema");
    const [scrollDirection, setScrollDirection] = useState(null);

    const [t, i18n] = useTranslation("common");
    AOS.init();

    const entertainmentChangeHandler = (value) => {
        setActiveTab(value)
    }


    useEffect(() => {
        changeTopNavHandler("BUSINESS_AREA")
    })
    useEffect(() => {
        let lastScrollY = window.pageYOffset;

        const updateScrollDirection = () => {
            const scrollY = window.pageYOffset;
            const direction = scrollY > lastScrollY ? "down" : "up";
            if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
                setScrollDirection(direction);
            }
            lastScrollY = scrollY > 0 ? scrollY : 0;
        };
        window.addEventListener("scroll", updateScrollDirection); // add event listener
        return () => {
            window.removeEventListener("scroll", updateScrollDirection); // clean up
        }
    }, [scrollDirection]);

    return (
        <main>
            <div className={`flex text-sm font-medium sticky bg-white top-0  ${scrollDirection === "down" ? "-top-24" : "top-0"}  md:text-lg transition-all duration-500 z-30`}>
                <Link to="/" className={`text-natural-900 hover:bg-primary-50 border-spacing-1 py-6 px-8 md:px-14 cursor-pointer`}>{t("ShoppingArea")}</Link>
                <Link to="/business-area" className={`text-natural-900 bg-white border-b-2 border-primary-600  hover:bg-primary-50 border-spacing-1 py-6 px-8 md:px-14 cursor-pointer`}>{t("BusinessArea")}</Link>
            </div>
            <section className='relative flex items-center justify-center h-screen overflow-hidden'>
                <img
                    alt="Roshn front logo"
                    src={BusinessAreaBg}
                    width={10}
                    height={10}
                    color='#fffff'
                    className='absolute z-10 w-auto min-w-full min-h-full bg-video__content max-w-none'
                />

                <div className="absolute top-0 z-40 w-full" >

                    {/* <div className="flex flex-row text-lg font-medium">
                <div className="px-8 py-6 bg-white border-b-2 cursor-pointer text-natural-900 border-spacing-1 border-primary-600 hover:bg-primary-50 md:px-14">Shopping Area</div>
                <div className="py-6 cursor-pointer text-natural-900 px-14 hover:bg-primary-50">Business Area</div>
            </div> */}
                    {
                        language != "arabic" ? (
                            <div className="flex items-center justify-between w-full px-8 py-6 text-white shadow-sm hero-with-nav-sec md:px-14 shadow-primary-50 ">
                                <CiMenuBurger className='w-6 h-6 cursor-pointer' onClick={() => setOpenSideNav(!openSideNav)} />
                                <Link to="/">
                                    <img
                                        data-aos="fade-in"

                                        alt="logo"
                                        src={RoshnFrontLogo}
                                        // color='#2A1815'
                                        width={100}
                                        height={65}
                                    />
                                </Link>
                                <a className="capitalize cursor-pointer" onClick={() => changeLanguageHandler("ar")}>العربية</a>
                            </div>
                        ) : (
                            <div className="flex items-center justify-between w-full px-8 py-6 text-white shadow-sm hero-with-nav-sec md:px-14 shadow-primary-50 ">
                                <CiMenuBurger className='w-6 h-6 cursor-pointer' onClick={() => setOpenSideNav(!openSideNav)} />
                                <Link to="/">
                                    <img
                                        alt="logo"
                                        src={RoshnFrontLogo}
                                        // color='#2A1815'
                                        width={100}
                                        height={65}
                                    />
                                </Link>
                                <a className="capitalize cursor-pointer" onClick={() => changeLanguageHandler("en")}>English</a>
                            </div>
                        )
                    }
                    <div className='flex px-4 mt-20 sm:px-14 sm:mt-32'>
                        <div className='text-white'>
                            <div className={`flex flex-nowrap  items-baseline justify-center ${i18n.language === 'en' ? 'space-x-2 sm:justify-start' : 'space-x-reverse space-x-2 sm:justify-start'}`}>
                                <img
                                    alt="Roshn front logo"
                                    src={BrandPatternDot}
                                    width={10}
                                    height={10}
                                    color='#fffff'
                                />
                                <h3 className={`font-light text-sm sm:text-base  ${i18n.language === 'en' ? 'tracking-widestExtra' : ''}`}> {t("BusinessArea")} </h3>
                            </div>
                            <h1 className={`my-8 leading-5 sm:leading-10 text-2xl text-center sm:text-6xl sm:my-14 ${i18n.language === 'en' ? 'sm:text-left' : 'sm:text-right'}`}> {t("businessAreaPage.hero.title")} <br /> <br /> <span className='font-light'><strong>{t("businessAreaPage.hero.subTitle")}</strong></span> </h1>

                            {/* <div className="relative items-center bg-transparent">
                                <div className={`absolute ${i18n.language === 'en' ? 'right-0' : 'left-0'} top-2`}>
                                    <AiOutlineSearch className='w-5 h-5 text-blue-600' />
                                </div>
                                <input type="text" className="bg-transparent pb-2 text-lg placeholder-white mt-0 block w-full px-0.5 border-0 border-b border-natural-secondary-50 focus:ring-0 focus:border-black focus:pb-4 focus:border-0 focus:border-b focus:border-natural-secondary-50 focus-visible:border-0 focus-visible:border-b focus-visible:border-natural-secondary-50 mr-4" placeholder={t('searchPlaceHolder')} />
                            </div> */}
                            <div className='flex flex-wrap justify-center my-8 gap-x-2 sm:justify-start gap-y-4'>
                                <Link to={{
                                    pathname: '/listing',
                                    query: "business-community"
                                }} className='flex items-center justify-between w-40 px-4 py-3 text-white border border-transparent rounded-md sm:w-1/3 bg-primary-50 bg-opacity-20 backdrop-blur-3xl drop-shadow-lg hover:border-white'>
                                    <div className='flex items-center flex-nowrap'>
                                        <img
                                            alt="Roshn front logo"
                                            src={CommunityIcon}
                                            className='w-5 h-5'
                                            color='#fffff'
                                        />
                                        <span className='pt-1 mx-2 text-xs text-white opacity-100 sm:text-base'>{t("listing.businessArea.community")}</span>
                                    </div>
                                    {i18n.language === "en" ?

                                        <BsChevronRight color='#fffff' className='w-4' />

                                        :
                                        <BsChevronLeft color='#fffff' className='w-4' />
                                    }                               </Link>
                                <Link to={{
                                    pathname: '/listing',
                                    query: "business-dine"
                                }} className='flex items-center justify-between w-40 px-4 py-3 text-white border border-transparent rounded-md sm:w-1/3 bg-primary-50 bg-opacity-20 backdrop-blur-3xl drop-shadow-lg hover:border-white'>
                                    <div className='flex items-center flex-nowrap'>
                                        <img
                                            alt="Roshn front logo"
                                            src={FoodIcon}
                                            className='w-5 h-5'
                                            color='#fffff'
                                        />
                                        <span className='pt-1 mx-2 text-xs text-white opacity-100 sm:text-base'>{t("listing.businessArea.dine")}</span>
                                    </div>
                                    {i18n.language === "en" ?

                                        <BsChevronRight color='#fffff' className='w-4' />

                                        :
                                        <BsChevronLeft color='#fffff' className='w-4' />
                                    }
                                </Link>
                                <Link to={{
                                    pathname: '/listing',
                                    query: "business-services"
                                }} className='flex items-center justify-between w-40 px-4 py-3 text-white border border-transparent rounded-md sm:w-1/3 bg-primary-50 bg-opacity-20 backdrop-blur-3xl drop-shadow-lg hover:border-white'>
                                    <div className='flex items-center flex-nowrap'>
                                        <img
                                            alt="Roshn front logo"
                                            src={ServicesIcon}
                                            className='w-5 h-5'
                                            color='#fffff'
                                        />
                                        <span className='pt-1 mx-2 text-xs text-white opacity-100 sm:text-base'>{t("listing.businessArea.services")}</span>
                                    </div>
                                    {i18n.language === "en" ?

                                        <BsChevronRight color='#fffff' className='w-4' />
                                        :
                                        <BsChevronLeft color='#fffff' className='w-4' />
                                    }
                                </Link>
                                <Link to={{
                                    pathname: '/listing',
                                    query: "business-events"
                                }} className='flex items-center justify-between w-40 px-4 py-3 text-white border border-transparent rounded-md sm:w-1/3 bg-primary-50 bg-opacity-20 backdrop-blur-3xl drop-shadow-lg hover:border-white'>
                                    <div className='flex items-center flex-nowrap'>
                                        <img
                                            alt="Roshn front logo"
                                            src={EventsIcon}
                                            className='w-5 h-5'
                                            color='#fffff'
                                        />
                                        <span className='pt-1 mx-2 text-xs text-white opacity-100 sm:text-base'>{t("listing.businessArea.events")}</span>
                                    </div>
                                    {i18n.language === "en" ?

                                        <BsChevronRight color='#fffff' className='w-4' />

                                        :
                                        <BsChevronLeft color='#fffff' className='w-4' />
                                    }
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>

                {/* <StickyTootip /> */}
            </section>
            {/* Disable - No news */}
            {/* <section data-aos="fade-up" data-aos-delay="100"
                data-aos-duration="500" className='mt-14'>
                <div className='px-4 md:px-14'>
                    <h3 className='text-2xl md:text-5xl tracking-[.24em] text-natural-secondary-900 font-semibold'>{t('businessAreaPage.news.title')}</h3>
                </div>
                <NewsSlider />
            </section> */}
            {/* 
            <section data-aos="fade-up" data-aos-delay="100"
                data-aos-duration="500" id='scrollTarget' className='mt-14'>
                <div className='px-4 md:px-14'>
                    <h3 data-aos={`${i18n.language === 'en' ? "fade-right" : "fade-left"} `} data-aos-delay="100"
                        data-aos-duration="700" className='text-2xl md:text-5xl tracking-[.24em] text-natural-secondary-900 font-extrabold'>{t('whatsNew')}</h3>
                </div>
                <WhatsNewSection businessArea={true} />
            </section> */}

            <ThriveSection />

            {/* <section className='mt-14'>
                <h1 className='text-base text-center lg:text-5xl'>
                    Home for World-Class Organizations
                </h1>
                <div class=" inline-flex flex-nowrap  mt-8">
                    <ul class="flex items-center justify-center gap-10 md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll">
                        <li>
                            <img src={LogoFooter} width={500}
                                height={500} alt="Facebook" />
                        </li>
                        <li>
                            <img src={LogoFooter} width={500}
                                height={500} alt="Disney" />
                        </li>
                        <li>
                            <img src={LogoFooter} width={500}
                                height={500} alt="Airbnb" />
                        </li>
                        <li>
                            <img src={LogoFooter} width={500}
                                height={500} alt="Apple" />
                        </li>
                        <li>
                            <img src={LogoFooter} width={500}
                                height={500} alt="Spark" />
                        </li>
                        <li>
                            <img src={LogoFooter} width={500}
                                height={500} alt="Samsung" />
                        </li>
                        <li>
                            <img src={LogoFooter} width={500}
                                height={500} alt="Quora" />
                        </li>
                        <li>
                            <img src={LogoFooter} width={500}
                                height={500} alt="Sass" />
                        </li>
                    </ul>
                    <ul class="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll" aria-hidden="true">
                        <li>
                            <img src={LogoFooter} width={500}
                                height={500} alt="Facebook" />
                        </li>
                        <li>
                            <img src={LogoFooter} width={500}
                                height={500} alt="Disney" />
                        </li>
                        <li>
                            <img src={LogoFooter} width={500}
                                height={500} alt="Airbnb" />
                        </li>
                        <li>
                            <img src={LogoFooter} width={500}
                                height={500} alt="Apple" />
                        </li>
                        <li>
                            <img src={LogoFooter} width={500}
                                height={500} alt="Spark" />
                        </li>
                        <li>
                            <img src={LogoFooter} width={500}
                                height={500} alt="Samsung" />
                        </li>
                        <li>
                            <img src={LogoFooter} width={500}
                                height={500} alt="Quora" />
                        </li>
                        <li>
                            <img src={LogoFooter} width={500}
                                height={500} alt="Sass" />
                        </li>
                    </ul>
                </div>
            </section> */}

            <Footer />

            <div className={`${openSideNav ? "block" : "hidden"} flex flex-col justify-between fixed left-0 top-0 ${i18n.language === "en" ? 'bg-right' : 'bg-left'} px-8 md:px-14 py-14 overflow-hidden w-full h-full z-50 bg-white`} style={{ backgroundImage: `url(${NavBarBg})`, backgroundRepeat: 'no-repeat' }}>
                <div className='flex justify-between pb-6 border-b text-natural-900 border-natural-secondary-300'>
                    <h3 className='text-base ' style={{ letterSpacing: '.24rem' }}>
                        {t("BusinessArea")}
                    </h3>
                    <AiOutlineClose className='w-6 h-6 cursor-pointer' onClick={() => setOpenSideNav(false)} />
                </div>
                <div className='mt-10 text-natural-900'>
                    <div className="">
                        <div className='flex items-center gap-x-4 '>
                            <img
                                alt="pattern"
                                src={communityIconColor}
                                color='#fffff'
                                className='w-8 h-8 mt-3'
                            />
                            {/* <LiaShoppingBagSolid className="w-8 h-8 p-1 mt-4 rounded-md bg-primary-100 text-natural-secondary-700" /> */}
                            <Link to={{
                                pathname: '/listing',
                                query: "business-community"
                            }} className='mt-5 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.businessArea.community")}</Link>
                        </div>
                        <div className='flex items-center mt-4 gap-x-4 md:mt-8'>
                            <img
                                alt="Roshn front logo"
                                src={DineIcon}
                                className='w-8 h-8 mt-3'
                                color='#fffff'
                            />
                            <Link to={{
                                pathname: '/listing',
                                query: "dine"
                            }} className='mt-5 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.businessArea.dine")}</Link>
                        </div>
                        <div className='flex items-center mt-4 gap-x-4 md:mt-8'>
                            <img
                                alt="Roshn front logo"
                                src={elevatorIcon}
                                className='w-8 h-8 mt-3'
                                color='#fffff'
                            />
                            <Link to={{
                                pathname: '/listing',
                                query: "business-services"
                            }} className='mt-5 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.businessArea.services")}</Link>
                        </div>
                        <div className='flex items-center mt-4 gap-x-4 md:mt-8'>
                            <img
                                alt="Roshn front logo"
                                src={CalendarIcon}
                                className='w-8 h-8 mt-3'
                                color='#fffff'
                            />
                            <Link to={{
                                pathname: '/listing',
                                query: "business-events"
                            }} className='mt-5 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.businessArea.events")}</Link>
                        </div>
                        <div className='flex items-center mt-4 gap-x-4 md:mt-8'>
                            <img
                                alt="Roshn front logo"
                                src={VistiorsIcon}
                                className='w-8 h-8 mt-3'
                                color='#fffff'
                            />
                            <a target="_blank" href="https://frontvisit.com/" className='mt-5 text-base font-semibold transition duration-300 ease-in-out delay-150 md:text-2xl hover:-translate-y-2 hover:text-secondary-900'>{t("listing.businessArea.visitors")}</a>
                        </div>
                    </div>

                </div>

                <div className='flex flex-col items-center justify-center py-4 mt-6 space-y-2 font-medium border-t md:flex-row md:space-y-0 md:justify-between text-natural-900 border-natural-secondary-300 md:py-10'>
                    <Link to="/about" className='cursor-pointer'>{t("aboutRoshnFront")}</Link>
                    <a target="_blank" href="https://wayfinding.roshn.sa/amap/map.php?site=ROSHNFRONT" className='cursor-pointer'>{t("InteractiveMap")}</a>
                    <Link to="/get-in-touch" className='cursor-pointer'>{t("contactUs")}</Link>
                </div>
            </div>
        </main >
    );
}


