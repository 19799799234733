import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { AiOutlineUnorderedList, AiOutlineCalendar, AiOutlineClose, AiOutlineFacebook, AiOutlineInstagram, AiOutlineYoutube, AiOutlineFlag, AiOutlineLock } from "react-icons/ai";
import { IoIosArrowBack, IoIosArrowDown, IoIosArrowDropup, IoIosArrowForward, IoIosArrowUp } from "react-icons/io";
import { LiaShoppingBagSolid } from "react-icons/lia";
import { BiDrink, BiWine, BiLogoSquarespace } from "react-icons/bi";
import { MdMiscellaneousServices } from "react-icons/md";
import { FiHeadphones, FiSmile } from "react-icons/fi";
import { RiCarWashingLine } from "react-icons/ri";
import Background from '../../assets/images/get-in-touch-bg.png';
import FootLockerLogo from '../../assets/images/FL.png';
import FootLockerLogoBg from '../../assets/images/foot_locker_bg.jpeg';
import FootLockerLogoBanner from '../../assets/images/fl-2.jpeg';
import RoshnfrontLogoBlack from '../../assets/images/roshnfront-logo-black.svg'
import { PiTwitterLogoLight } from 'react-icons/pi';
import CheckIcon from '../../assets/images/icons/check-icon.svg'
import elevatorIcon from '../../assets/images/icons/elevator-icon.svg'

import jsonDataShopsEn from './data/newest_shop_list_en.json';
import jsonDataShopsAr from './data/newest_shop_list_ar.json';

import jsonDataBusiEn from './data/new_bus_list_en.json';
import jsonDataBusiAr from './data/new_bus_list_ar.json';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export const ServicesListing = ({ isOpen, type, openCardHandler, icon, topNavValue }) => {
    const [t, i18n] = useTranslation("common");

    return (
        isOpen && (

            <div>
                <div className='flex items-center mt-4 gap-x-2'>
                    <img
                        alt="Roshn front logo"
                        src={elevatorIcon}
                        className='w-8 h-8 '
                        color='#fffff'
                    />
                    <p className='pt-2 text-2xl font-semibold text-natural-900'>{t("listing.shoppingArea.services")}</p>

                </div>
                <div className='py-10' >
                    <h1 className='text-lg font-semibold'>{i18n.language === "en" ? topNavValue != "SHOPPING_AREA" ? jsonDataBusiEn.services.title : jsonDataShopsEn.services.title : topNavValue != "SHOPPING_AREA" ? jsonDataBusiAr.services.title : jsonDataShopsAr.services.title}</h1>
                    <p className='w-3/4 mt-4 text-base font-normal leading-8'> {i18n.language === "en" ? topNavValue != "SHOPPING_AREA" ? jsonDataBusiEn.services.body : jsonDataShopsEn.services.body : topNavValue != "SHOPPING_AREA" ? jsonDataBusiAr.services.body : jsonDataShopsAr.services.body}
                    </p>
                </div>
                <div className='flex flex-wrap gap-6 py-4'>
                    {
                        i18n.language === "en" ?
                            topNavValue != "SHOPPING_AREA" ?
                                jsonDataBusiEn.services.list.map((item) => (
                                    <div key={item.title} className={`w-64 py-6 ${i18n.language === "en" ? 'pl-6' : 'pr-6'} border hover:border-natural-900 border-natural-50 rounded-2xl`}>
                                        <div className='flex items-center gap-2'>
                                            <img
                                                alt="Roshn front logo"
                                                src={CheckIcon}
                                                className='w-4 h-4 lg:h-5 lg:w-5'
                                                color='#fffff'
                                            />
                                            <span className='w-full border-t-2 border-natural-200'></span>
                                        </div>

                                        <div className='mt-6'>
                                            <h1 className='text-lg font-semibold'>
                                                {item.title}
                                            </h1>
                                            <p className='w-48 mt-2 text-xs font-normal leading-5'>
                                                {item.body}
                                            </p>
                                        </div>

                                    </div>
                                )) :
                                jsonDataShopsEn.services.list.map((item) => (
                                    <div key={item.title} className={`w-64 py-6 ${i18n.language === "en" ? 'pl-6' : 'pr-6'} border hover:border-natural-900 border-natural-50 rounded-2xl`}>
                                        <div className='flex items-center gap-2 '>
                                            <img
                                                alt="Roshn front logo"
                                                src={CheckIcon}
                                                className='w-4 h-4 lg:h-5 lg:w-5'
                                                color='#fffff'
                                            />
                                            <span className='w-full border-t-2 border-natural-200'></span>
                                        </div>

                                        <div className='mt-6'>
                                            <h1 className='text-lg font-semibold'>
                                                {item.title}
                                            </h1>
                                            <p className='w-48 mt-2 text-xs font-normal leading-5'>
                                                {item.body}
                                            </p>
                                        </div>
                                    </div>

                                )) :
                            topNavValue != "SHOPPING_AREA" ?
                                jsonDataBusiAr.services.list.map((item) => (
                                    <div key={item.title} className={`w-64 py-6 ${i18n.language === "en" ? 'pl-6' : 'pr-6'} border hover:border-natural-900 border-natural-50 rounded-2xl`}>
                                        <div className='flex items-center gap-2 '>
                                            <img
                                                alt="Roshn front logo"
                                                src={CheckIcon}
                                                className='w-4 h-4 lg:h-5 lg:w-5'
                                                color='#fffff'
                                            />
                                            <span className='w-full border-t-2 border-natural-200'></span>
                                        </div>

                                        <div className='mt-6'>
                                            <h1 className='text-lg font-semibold'>
                                                {item.title}
                                            </h1>
                                            <p className='w-48 mt-2 text-xs font-normal leading-5'>
                                                {item.body}
                                            </p>
                                        </div>
                                    </div>

                                )) :

                                jsonDataShopsAr.services.list.map((item) => (
                                    <div key={item.title} className={`w-64 py-6 ${i18n.language === "en" ? 'pl-6' : 'pr-6'} border hover:border-natural-900 border-natural-50 rounded-2xl`}>
                                        <div className='flex items-center gap-2 '>
                                            <img
                                                alt="Roshn front logo"
                                                src={CheckIcon}
                                                className='w-4 h-4 lg:h-5 lg:w-5'
                                                color='#fffff'
                                            />
                                            <span className='w-full border-t-2 border-natural-200'></span>
                                        </div>

                                        <div className='mt-6'>
                                            <h1 className='text-lg font-semibold'>
                                                {item.title}
                                            </h1>
                                            <p className='w-48 mt-2 text-xs font-normal leading-5'>
                                                {item.body}
                                            </p>
                                        </div>
                                    </div>

                                ))
                    }
                </div>
                <div className={`flex justify-between text-base font-medium ${i18n.language === "en" ? "mr-14" : "ml-14"} mt-8 mb-6 py-5 px-4  bg-secondary-50 rounded-2xl`}>
                    <p className=''>
                        {t('listing.serviceQA')}
                    </p>
                    <div className='flex items-center gap-2'>
                        <Link to="/get-in-touch" className='underline cursor-pointer'>{t('listing.serviceCTA')}</Link>
                        {i18n.language === "en" ?
                            <IoIosArrowForward className='w-5 h-5' />
                            :
                            <IoIosArrowBack className='w-5 h-5' />
                        }
                    </div>
                </div>
            </div>
        )


    )
}