import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import ScrollButtonIcon from '../../assets/images/icons/more-icon.svg';

export const ScrollButton = () => {
    const [t, i18n] = useTranslation("common");

    return (

        <div className={`absolute hidden ${i18n.language === "en" ? "flex right-5 bottom-10" : "flex right-auto left-5 bottom-10"} md:flex  gap-6 z-30`}>
            <img
                alt="logo"
                src={ScrollButtonIcon}
            // color='#2A1815'
            // className='h-40  w-1'
            />
            <a href='#scrollTarget' className={`text-white transform ${i18n.language === "en" ? "-rotate-90" : "rotate-90"}`}>
                {t("DiscoverMore")}
            </a>
        </div>
    )
}