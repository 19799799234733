import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { useTranslation } from "react-i18next";
import { AiOutlineUnorderedList, AiOutlineCalendar, AiOutlineClose, AiOutlineFacebook, AiOutlineInstagram, AiOutlineYoutube, AiOutlineFlag } from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowDropup, IoIosArrowUp } from "react-icons/io";
import { MdOutlineArrowOutward } from "react-icons/md";

import { LiaShoppingBagSolid } from "react-icons/lia";
import { BiDrink, BiWine, BiLogoSquarespace } from "react-icons/bi";
import { MdMiscellaneousServices } from "react-icons/md";
import Background from '../../assets/images/get-in-touch-bg.png';
import FootLockerLogo from '../../assets/images/FL.png';
import FootLockerLogoBg from '../../assets/images/foot_locker_bg.jpeg';
import FootLockerLogoBanner from '../../assets/images/fl-2.jpeg';
import RoshnfrontLogoBlack from '../../assets/images/roshnfront-logo-black.svg'
import { PiTwitterLogoLight } from 'react-icons/pi';
import { ShopListing } from './ShopListing';
import { DineListing } from './DineListing';
import { EntertainmentListing } from './EntertainmentListing';
import { ServicesListing } from './ServicesListing';
import { EventsListing } from './EventsListing';
import { DealsListing } from './DealsListing';
import { CommunityLisitng } from './CommunityListing';
import communityIcon from '../../assets/images/icons/community-icon-1.svg'
import DineIcon from '../../assets/images/icons/dine-icon.svg'
import elevatorIcon from '../../assets/images/icons/elevator-icon.svg'
import CalendarIcon from '../../assets/images/icons/calendar-icon-color.svg'
import VistiorsIcon from '../../assets/images/icons/visitor-icon.svg'


import jsonDataBusEn from './data/new_bus_list_en.json';
import jsonDataBusAr from './data/new_bus_list_ar.json';
import { FiArrowUpLeft, FiArrowUpRight } from 'react-icons/fi';

export const ListingBusinessDesktop = ({ topNavValue, changeTopNavHandler }) => {
    const [t, i18n] = useTranslation("common");
    let location = useLocation();

    const [openCard, setOpenCard] = useState(false);
    const [openCommunity, setOpenCommunity] = useState(true);
    const [openDine, setOpenDine] = useState(false);
    const [openEntertainment, setOpenEntertainment] = useState(false);
    const [openServices, setOpenServices] = useState(false);
    const [openEvents, setOpenEvents] = useState(false);
    const [openDeals, setOpenDeals] = useState(false);
    const [cardItem, setCardItem] = useState('')
    const [filteredCategories, setFilterCategories] = useState([]);

    const openCardHandler = (item) => {
        setCardItem(item)
        setOpenCard(!openCard)
    }

    const openDineHandler = () => {
        setOpenCommunity(false);
        setOpenServices(false)
        setOpenEvents(false)
        setOpenDine(!openDine)
    }

    const handelCommunityOpen = () => {
        setOpenDine(false)
        setOpenEvents(false)
        setOpenServices(false)
        setOpenCommunity(!openCommunity)
    }

    const openServicesHandler = () => {
        setOpenCommunity(false);
        setOpenDine(false)
        setOpenEvents(false)
        setOpenServices(!openServices)
    }
    const openEventsHandler = () => {
        setOpenCommunity(false);
        setOpenDine(false)
        setOpenEntertainment(false)
        setOpenServices(false)
        setOpenEvents(!openEvents)
    }

    const handleSelectedCategory = (category) => {
        setFilterCategories([...filteredCategories, category]);
    };


    const handelRemoveCategory = (category) => {
        setFilterCategories(filteredCategories.filter((item) => item !== category));
    };

    useEffect(() => {
        if (location.query === "business-community") {
            changeTopNavHandler("BUSINESS_AREA")
            setOpenCommunity(true);
            setOpenDine(false)
            setOpenEvents(false)
            setOpenServices(false)
        }
        if (location.query === "business-dine") {
            setOpenCommunity(false);
            setOpenDine(true)
            setOpenEvents(false)
            setOpenServices(false)
        }
        if (location.query === "business-services") {
            setOpenCommunity(false);
            setOpenDine(false)
            setOpenServices(true)
            setOpenEvents(false)
        }

        if (location.query === "business-events") {
            if (location.card) {
                openCardHandler(i18n.language === "en" ? jsonDataBusEn.events.find((item) => item.name === location.card) : jsonDataBusAr.events.find((item) => item.name === location.card))
            }
            setOpenCommunity(false);
            setOpenDine(false)
            setOpenServices(false)
            setOpenEvents(true)
        }
    }, [location.query]);

    return (
        <div className={`releative flex gap-x-6 ${i18n.language === 'en' ? 'ml-14' : 'mr-14'} `}>
            <div className='w-80'>
                <div className='flex justify-center w-full gap-3 p-4 border rounded-lg align-center border-natural-secondary-200'>
                    <AiOutlineUnorderedList className='w-6 h-6' />
                    <button className='text-xl'>
                        {t("listing.title")}
                    </button>
                </div>
                {/* <input type="text" className="mt-4 bg-transparent pb-2 text-lg placeholder-natural-secondary-400  block w-full px-0.5 border-0 border-b border-natural-secondary-100 focus:ring-0 focus:border-black focus:pb-4 focus:border-0 focus:border-b focus:border-natural-secondary-300 focus-visible:border-0 focus-visible:border-b focus-visible:border-natural-secondary-50 " placeholder={t("listing.searchText")} /> */}

                <div className='mt-4 border rounded-lg border-natural-secondary-100'>
                    <div>
                        <div onClick={handelCommunityOpen} className='flex items-center justify-between p-4 cursor-pointer'>
                            <div className='flex items-center gap-x-2'>
                                <img
                                    alt="pattern"
                                    src={communityIcon}
                                    color='#fffff'
                                    className='w-8 h-8'
                                />
                                <p className='pt-2 font-semibold text-natural-900'>{t("listing.businessArea.community")}</p>

                            </div>
                            {openCommunity ? (
                                <IoIosArrowUp className='w-6 h-6 p-1 rounded-full bg-natural-secondary-100' />
                            ) : (
                                <IoIosArrowDown className='w-4 h-4' />
                            )}
                        </div>

                    </div>
                    <div>
                        <div onClick={openServicesHandler} className='flex items-center justify-between p-4 cursor-pointer'>
                            <div className='flex items-center gap-x-2'>
                                <img
                                    alt="pattern"
                                    src={elevatorIcon}
                                    color='#fffff'
                                    className='w-8 h-8'
                                />
                                <p className='pt-2 font-semibold text-natural-900'>{t("listing.businessArea.services")}</p>

                            </div>
                            {openServices ? (
                                <IoIosArrowUp className='w-6 h-6 p-1 rounded-full bg-natural-secondary-100' />
                            ) : (
                                <IoIosArrowDown className='w-4 h-4' />
                            )}
                        </div>
                    </div>

                    <div>
                        <div onClick={openEventsHandler} className='flex items-center justify-between p-4 cursor-pointer'>
                            <div className='flex items-center gap-x-2'>

                                <img
                                    alt="pattern"
                                    src={CalendarIcon}
                                    color='#fffff'
                                    className='w-8 h-8'
                                />
                                <p className='pt-2 font-semibold text-natural-900'>{t("listing.businessArea.events")}</p>

                            </div>
                            {openEvents ? (
                                <IoIosArrowUp className='w-6 h-6 p-1 rounded-full bg-natural-secondary-100' />
                            ) : (
                                <IoIosArrowDown className='w-4 h-4' />
                            )}
                        </div>

                    </div>

                    <div>
                        <div onClick={openDineHandler} className='flex items-center justify-between p-4 cursor-pointer'>
                            <div className='flex items-center gap-x-2'>
                                <img
                                    alt="pattern"
                                    src={DineIcon}
                                    color='#fffff'
                                    className='w-8 h-8'
                                />
                                <p className='pt-2 font-semibold text-natural-900'>{t("listing.businessArea.dine")}</p>

                            </div>
                            {openDine ? (
                                <IoIosArrowUp className='w-6 h-6 p-1 rounded-full bg-natural-secondary-100' />
                            ) : (
                                <IoIosArrowDown className='w-4 h-4' />
                            )}
                        </div>

                        {/* List */}
                        {openDine && (
                            <div className='bg-natural-secondary-50'>
                                <div className={`flex py-4 mx-7 bg-natural-secondary-50 ${i18n.language === "en" ? "border-l" : "border-r"} border-natural-400 justify-between items-center cursor-pointer`}>
                                    <div className={`px-8 cursor-pointer ${filteredCategories.includes("cafes") && "font-bold"}`} onClick={() => handleSelectedCategory("cafes")}>
                                        <p className='pt-2 text-natural-900'>{t("categories.cafes")}</p>
                                    </div>
                                    {filteredCategories.includes("cafes") &&
                                        <AiOutlineClose onClick={() => handelRemoveCategory("cafes")} className='w-6 h-6 p-1 rounded-full cursor-pointer bg-natural-secondary-100' />
                                    }
                                </div>
                                <div className={`flex py-4 mx-7 bg-natural-secondary-50 ${i18n.language === "en" ? "border-l" : "border-r"} border-natural-400 justify-between items-center cursor-pointer`}>
                                    <div className={`px-8 cursor-pointer ${filteredCategories.includes("restaurants") && "font-bold"}`} onClick={() => handleSelectedCategory("restaurants")}>
                                        <p className='pt-2 text-natural-900'>{t("categories.restaurants")}</p>
                                    </div>
                                    {filteredCategories.includes("restaurants") &&
                                        <AiOutlineClose onClick={() => handelRemoveCategory("restaurants")} className='w-6 h-6 p-1 rounded-full cursor-pointer bg-natural-secondary-100' />
                                    }
                                </div>
                                <div className={`flex py-4 mx-7 bg-natural-secondary-50 ${i18n.language === "en" ? "border-l" : "border-r"} border-natural-400 justify-between items-center cursor-pointer`}>
                                    <div className={`px-8 cursor-pointer ${filteredCategories.includes("Ice Cream") && "font-bold"}`} onClick={() => handleSelectedCategory("Ice Cream")}>
                                        <p className='pt-2 text-natural-900'>{t("categories.IceCream")}</p>
                                    </div>
                                    {filteredCategories.includes("Ice Cream") &&
                                        <AiOutlineClose onClick={() => handelRemoveCategory("Ice Cream")} className='w-6 h-6 p-1 rounded-full cursor-pointer bg-natural-secondary-100' />
                                    }
                                </div>
                            </div>
                        )}
                    </div>

                    <a target="_blank" href="https://frontvisit.com/" className='flex items-center justify-between p-4 cursor-pointer'>
                        <div className='flex items-center gap-x-2'>
                            <img
                                alt="pattern"
                                src={VistiorsIcon}
                                color='#fffff'
                                className='w-8 h-8'
                            />
                            <p className='pt-2 font-semibold text-natural-900'>{t("listing.businessArea.visitors")}</p>

                        </div>
                        {i18n.language === "en" ?

                            <FiArrowUpRight className='p-1 h-7 w-7 text-natural-900' />
                            :
                            <FiArrowUpLeft className='p-1 h-7 w-7 text-natural-900' />
                        }
                    </a>

                </div>
            </div>
            <div className='flex-1 overflow-y-scroll' style={{ height: "700px" }}>
                <h1 className={`mt-1 text-natural-secondary-900 font-normal ${i18n.language === 'en' ? 'tracking-widestExtra' : ''}`} >{t("listing.businessArea.mainTitle")}</h1>
                <CommunityLisitng topNavValue={topNavValue} isOpen={openCommunity} openCardHandler={openCardHandler} />

                <DineListing topNavValue={topNavValue} isOpen={openDine} openCardHandler={openCardHandler} filteredCategories={filteredCategories} />

                <EntertainmentListing topNavValue={topNavValue} isOpen={openEntertainment} openCardHandler={openCardHandler} />

                <ServicesListing topNavValue={topNavValue} isOpen={openServices} openCardHandler={openCardHandler} />

                <EventsListing topNavValue={topNavValue} isOpen={openEvents} openCardHandler={openCardHandler} />


            </div>

            {/* Crad comp */}
            <div className={`${openCard ? 'block' : 'hidden'} bg-white shadow-xl z-10 mt-20  fixed top-0 right-0 left-0 rounded-xl  w-2/3  ml-auto mr-auto`}>
                <div className='relative'>
                    {/* <div className='absolute flex gap-2 top-6 left-6'>
                        <a href='#' className='p-2 bg-opacity-50 rounded-md cursor-pointer bg-natural-secondary-400'>
                            <PiTwitterLogoLight className='w-6 h-6 text-white' />
                        </a>
                        <a href='#' className='p-2 bg-opacity-50 rounded-md cursor-pointer bg-natural-secondary-400'>
                            <AiOutlineFacebook className='w-6 h-6 text-white' />
                        </a>
                        <a href='#' className='p-2 bg-opacity-50 rounded-md cursor-pointer bg-natural-secondary-400'>
                            <AiOutlineInstagram className='w-6 h-6 text-white' />
                        </a>
                        <a href='#' className='p-2 bg-opacity-50 rounded-md cursor-pointer bg-natural-secondary-400'>
                            <AiOutlineYoutube className='w-6 h-6 text-white' />
                        </a>
                    </div> */}
                    <AiOutlineClose onClick={openCardHandler} className='absolute w-6 h-6 p-1 bg-white rounded-full cursor-pointer w top-6 right-6' />
                    {!openEvents &&
                        <div className='absolute w-24 h-24 left-14 top-60'>
                            <img
                                alt="pattern"
                                src={cardItem.logo}
                                color='#fffff'
                                className='object-contain w-full h-full bg-white border rounded-full border-natural-secondary-200'
                            />
                        </div>
                    }
                    <div className='absolute flex items-center gap-1 px-4 py-2 bg-primary-50 rounded-3xl right-14' style={{ top: '275px' }}>
                        <AiOutlineFlag className="w-6 h-6" />
                        <div className='pt-1'>{t('gates.title')} {cardItem.gate}</div>
                    </div>
                </div>
                <img
                    alt="pattern"
                    src={cardItem.banner}
                    className='object-cover w-full h-72 rounded-t-2xl'
                />
                <div className='flex justify-between gap-16 mx-16 mt-20 mb-12'>
                    <div className='w-full md:w-1/2'>
                        <h1 className='block text-2xl font-semibold '>{cardItem.name}</h1>
                        {openCommunity ?
                            <span className='inline-block text-natural-secondary-600'>{t('building')} #{cardItem.buldingNumber}</span>
                            :
                            <span className='inline-block capitalize text-natural-secondary-600'>{cardItem.category}</span>
                        }


                        <div className='h-40 mt-6 overflow-scroll'>
                            {cardItem.description}
                        </div>
                    </div>

                    <div className='w-full md:w-1/2'>
                        {!openEvents &&
                            <div className='flex gap-2'>
                                <a type='button' target='_blank' href="https://maps.app.goo.gl/oAvV2ZgnDNW7rrdd9" className='w-full px-2 pt-3 pb-2 text-center text-white rounded-md bg-primary-600 hover:bg-primary-800'>{t("ShowInMap")}</a>
                                {/* <a type='button' target='_blank' href={cardItem.website} className='w-full px-2 pt-3 pb-2 text-center bg-white border rounded-md border-natural-900 hover:bg-natural-secondary-100 text-natural-900'>{t("BrandWebsite")}</a> */}
                            </div>
                        }

                        {openEvents &&
                            <>
                                <div className='mt-6 text-base'>
                                    <h1 className='font-semibold uppercase'>{t("openningHoursTitle")}</h1>
                                    <p>{t("listing.shoppingArea.openningHoursBody")}</p>
                                </div>
                                <div className='mt-6 text-base'>
                                    <h1 className='font-semibold uppercase'>{t("listing.shoppingArea.location")}</h1>
                                    <p>{cardItem.location}</p>
                                </div>
                                <div className='mt-6 text-base'>
                                    <h1 className='font-semibold uppercase'>{t("listing.shoppingArea.contactNumber")}</h1>
                                    <p>{cardItem.contactNumber}</p>
                                </div>
                            </>
                        }

                    </div>
                </div>
            </div>
        </div >
    )
}


