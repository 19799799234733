
import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import bg404 from '../../assets/images/404-image.png'
import { IoIosArrowForward } from 'react-icons/io';


export const PageNotFound = ({ }) => {
    const [t, i18n] = useTranslation("common");

    return (
        <div className="flex flex-col items-center justify-center min-h-screen text-center bg-gray-100">
            <img
                alt="Roshn front logo"
                src={bg404}
                width={10}
                height={10}
                color='#fffff'
                className='w-2/3 md:w-1/3'
            />
            <p className="mt-8 text-sm text-supporting-300 md:text-3xl">
                {t("notFound.title")}
            </p>
            <div className='flex items-center justify-center gap-1 mt-4'>

                <a href="/" className="mt-1 text-base font-semibold rounded text-natural-900 hover:bg-blue-600">
                    {t("notFound.cta")}
                </a>
                <IoIosArrowForward className="w-5 h-5 text-natural-900" />
            </div>
        </div>
    )
}
