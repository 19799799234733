import React, { Component, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineFlag } from "react-icons/ai";
import { BiExpand } from "react-icons/bi";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import ShareIcon from '../../assets/images/icons/share-icon.svg';
import CalendarSmall from '../../assets/images/icons/calendar-small.svg';
import pinIcon from '../../assets/images/icons/pin-alt.svg';

const EventCard = ({ item, openCardHandler }) => {
    const [t, i18n] = useTranslation("common");

    const [oldSlide, setOldSlide] = useState(1);
    const [activeSlide, setActiveSlide] = useState(1);
    const [activeSlide2, setActiveSlide2] = useState(1);

    return (
        <div key={item.name} onClick={() => openCardHandler(item)} className="mx-4 my-6 bg-white shadow-xl rounded-xl">

            <img
                alt="pattern"
                src={item.banner}
                className='object-cover w-full rounded-t-xl'
            />
            <div className='p-4'>
                <div className="flex items-center justify-between">
                    <h1 className="text-base font-semibold">
                        {item.name}
                    </h1>
                    <img
                        alt="icon"
                        src={ShareIcon}
                        className='w-5 h-5'
                    />
                </div>
                <div className="mt-4">
                    <div className="flex gap-2">
                        <img
                            alt="icon"
                            src={CalendarSmall}
                            className='w-4 h-4'
                        />
                        <p className="font-light">{item.date}</p>
                    </div>
                    <div className="flex gap-2 my-2">
                        <img
                            alt="icon"
                            src={pinIcon}
                            className='w-4 h-4'
                        />
                        <p className="font-light">{item.location}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EventCard;